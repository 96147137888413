.slider-wrapper {
	overflow: hidden;
	width: calc(100% + 360px);
	margin-left: -180px;
}

.swiper {
	overflow: initial;
	width: calc(100% - 360px);
}

.swiper:not(.swiper-initialized) {
	opacity: 0;
}

.swiper.swiper-initialized {
	transition: opacity .5s ease-in-out;
	opacity: 1;
}

.swiper-slide {
	transition: opacity $transition;
	height: auto;
}

.swiper-slide:not(.swiper-slide-visible) {
	user-select: none;
	pointer-events: none;
	opacity: 0.2;
}

.swiper-button-prev,
.swiper-button-next {
	@include background("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 19L17 11.9997L9.00069 5' stroke='%230C0C3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E", $color: white);
	outline: none;
	width: 56px;
	height: 56px;
	border: 1px solid rgba(207, 215, 220, .25);
	border-radius: 56px;
	box-shadow: 0px 28px 53px rgba(0, 25, 80, 0.08), 0px 10.2205px 15.1703px rgba(0, 25, 80, 0.0551985), 0px 4.96184px 8.87643px rgba(0, 25, 80, 0.0445033), 0px 2.43239px 6.24775px rgba(0, 25, 80, 0.0354967), 0px 0.96177px 3.59913px rgba(0, 25, 80, 0.0248015);
	margin: 0;
	transition: $transition;

	&:hover,
	&:focus-visible {
		border-color: $pale--secondary;
	}
}

.swiper-button-prev::after,
.swiper-button-next::after {
	display: none;
}

.swiper-button-prev {
	transform: translateY(-50%) scaleX(-1);
	left: -15px;
}

.swiper-button-next {
	transform: translateY(-50%);
	right: -15px;
}

.swiper-button-disabled {
	display: none;
}
