.header {
    position: sticky;
	top: 0;
	background-color: white;
    padding: 20px 0;
    z-index: 10;

    @include max-width($tablet_wide) {
        padding: 0;
    }

    .wrapper {
        display: flex;
        flex-direction: column;

        @include max-width($tablet_wide) {
            padding: 0;
        }
    }

    .wrapper > * + * {
        margin-top: 20px;

        @include max-width($tablet_wide) {
            margin-top: 0;
        }
    }

    &_top {
        display: flex;
        align-items: center;
        z-index: 10;

        @include max-width($tablet_wide) {
			box-shadow: 0px 2px 16px rgba(0, 84, 121, 0.02), 0px 0.700079px 9px rgba(0, 84, 121, 0.02), 0px 0.339395px 8px rgba(0, 84, 121, 0.02);
            background-color: white;
            padding-right: 20px;
        }
    }

    &_top > * {
        flex-shrink: 0;
    }

    &_top > * + * {
        margin-left: 12px;
    }

    &_top .gs_button {
        @include max-width($phone) {
            margin-left: auto;
        }
    }

    &_bottom {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        z-index: 9;

        @include max-width($tablet_wide) {
            flex-direction: column;
            box-shadow: 0px 3px 64px rgba(0, 5, 22, 0.3);
            position: absolute;
            width: 100%;
            background-color: white;
            top: 64px;
			pointer-events: none;
			visibility: hidden;
            opacity: 0;
            padding: 16px;
            transition: $transition;
            max-height: calc(100vh - 64px);
            overflow-y: auto;
        }
    }

    &_bottom > * + * {
        margin-left: 8px;

        @include max-width($tablet_wide) {
            margin-left: 0;
            margin-top: 16px;
        }
    }

    &_bottom.opened {
        @include max-width($tablet_wide) {
			pointer-events: initial;
			visibility: visible;
            opacity: 1;
        }
    }

	.gs_link--active {
		color: $primary--dark;
	}

    nav {
        @include max-width($tablet_wide) {
            width: 100%;
        }
    }

    nav > ul {
        display: flex;
        flex-wrap: wrap;

        @include max-width($tablet_wide) {
            flex-direction: column;
        }
    }

    nav > ul li {
        margin-right: 16px;
        margin-bottom: 10px;

        @include max-width($tablet_wide) {
            margin-right: 0;
        }
    }

    nav > ul a {
        display: block;
    }

    .contacts {
        display: flex;
        flex-shrink: 0;

        @include max-width($tablet_wide) {
            flex-direction: column;
        }
    }

    .contacts > * + * {
        margin-left: 32px;

        @include max-width($tablet_wide) {
            margin-left: 0;
            margin-top: 12px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .buttons > * + * {
        margin-top: 8px;
    }

    .logo {
        margin-left: 2px;
    }

    .logo svg {
        @include max-width($tablet) {
            width: 100px;
        }
    }
}

// .header_overlay {

// 	@include min-width(1025px) {
// 		.header {
// 			color: white;

// 			path,
// 			circle {
// 				fill: white;
// 			}

// 			.gs_link--active {
// 				color: white;
// 				opacity: 0.5;
// 			}

// 			.gs_button {
// 				background-color: rgba(white, .25);
// 				color: white;
// 			}

// 			.gs_button:hover,
// 			.gs_button:focus-visible {
// 				background-color: rgba(white, .5);
// 			}
		
// 			.gs_button:active {
// 				background-color: $pale--secondary;
// 			}

// 			.gs_link:hover,
// 			.gs_link:focus-visible {
// 				color: white;
// 				opacity: 0.5;
// 			}

// 			.search_panel input[type='search'],
//             .search_panel input[type='search']::placeholder {
// 				color: white;
// 			}

// 			.search_panel input[type='search']:focus-visible {
// 				border-color: rgba(white, .5);
// 			}

//             .search_panel button[type='submit'] {
//                 @include background("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 17C6.68629 17 4 14.3137 4 11C4 7.68629 6.68629 5 10 5C13.3137 5 16 7.68629 16 11C16 14.3137 13.3137 17 10 17Z' stroke='%23CFD7DC' stroke-width='1.2' stroke-linejoin='round'/%3E%3Cpath d='M14.5 15.5L19 20' stroke='%23CFD7DC' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A", $position: 12px center);
//             }
// 		}
// 	}
// }
