.review_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    img {
        cursor: zoom-in;
        text-align: center;
        width: 100%;
        height: 100%;
        border: 1px solid $pale--secondary;
        border-radius: $radius;
        margin-bottom: 8px;

        @include max-width($tablet_wide) {
            max-width: 350px;
        }
    }

    > * + * {
        margin-top: 8px;
    }
}
