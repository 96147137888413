.footer {
	background-color: $background;
	padding: 40px 0;
	
	@include max-width($tablet) {
		padding: 20px 0;
	}
	
	&_container {
		display: flex;
		// justify-content: space-between;

		@include max-width($tablet) {
			flex-direction: column;
		}
	}

	&_container > * + * {
		margin-left: 64px;

		@include max-width($tablet_wide) {
			margin-left: 32px;
		}

		@include max-width($tablet) {
			margin-left: 0;
			margin-top: 32px;
		}
	}
	
	&_block > * + * {
		margin-left: 24px;

		@include max-width($tablet_wide) {
			margin-left: 16px;
		}

		@include max-width($tablet) {
			margin-left: 0;
			margin-top: 32px;
		}
	}

	&_block_content {
		display: flex;
		flex-direction: column;
	}

	&_block_content > * + * {
		margin-top: 24px;

		@include max-width($tablet) {
			margin-top: 16px;
		}
	}
}
