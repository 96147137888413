@for $i from 0 through 64 {
	@if $i % 2 == 0 {
		.gap_row_#{$i} > * + * {
			margin-left: #{$i}px;
		}
	
		.gap_column_#{$i} > * + * {
			margin-top: #{$i}px;
		}
	}
}

@for $i from 0 through 64 {
	@if $i % 2 == 0 {
		.gap_row_#{$i}--desktop_large > * + * {
			@include max-width($desktop_large) {
				margin-left: #{$i}px;
			}
		}
	
		.gap_column_#{$i}--desktop_large > * + * {
			@include max-width($desktop_large) {
				margin-top: #{$i}px;
			}
		}
	}
}

@for $i from 0 through 64 {
	@if $i % 2 == 0 {
		.gap_row_#{$i}--desktop > * + * {
			@include max-width($desktop) {
				margin-left: #{$i}px;
			}
		}
	
		.gap_column_#{$i}--desktop > * + * {
			@include max-width($desktop) {
				margin-top: #{$i}px;
			}
		}
	}
}

@for $i from 0 through 64 {
	@if $i % 2 == 0 {
		.gap_row_#{$i}--tablet_wide > * + * {
			@include max-width($tablet_wide) {
				margin-left: #{$i}px;
			}
		}
	
		.gap_column_#{$i}--tablet_wide > * + * {
			@include max-width($tablet_wide) {
				margin-top: #{$i}px;
			}
		}
	}
}

@for $i from 0 through 64 {
	@if $i % 2 == 0 {
		.gap_row_#{$i}--tablet > * + * {
			@include max-width($tablet) {
				margin-left: #{$i}px;
			}
		}
	
		.gap_column_#{$i}--tablet > * + * {
			@include max-width($tablet) {
				margin-top: #{$i}px;
			}
		}
	}
}

@for $i from 0 through 64 {
	@if $i % 2 == 0 {
		.gap_row_#{$i}--phone > * + * {
			@include max-width($phone) {
				margin-left: #{$i}px;
			}
		}
	
		.gap_column_#{$i}--phone > * + * {
			@include max-width($phone) {
				margin-top: #{$i}px;
			}
		}
	}
}
