.news_card {
    position: relative;
    display: flex;
	flex-direction: column;
    
    > * + * {
		margin-top: 8px;
	}

    .gs_link--stretched + * {
		margin-top: 0;
        margin-left: 0;
	}

    img {
		border: 1px solid $pale--tertiary;
		object-fit: cover;
		object-position: center;
		border-radius: $radius;
		width: 100%;
		height: 200px;

		@include max-width($phone) {
			height: 170px;
		}
	}

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__body > * + * {
        margin-top: 8px;
    }

    .h3 {
		font: inherit;
		transition: $transition;
	}

	.gs_link--stretched:hover ~ .news_card__body .h3,
	.gs_link--stretched:focus-visible ~ .news_card__body .h3 {
		color: $primary--dark;
	}
}

.news_card--first_item {
    grid-column: 1 / -1;
    flex-direction: row;

    @include max-width($tablet_wide) {
        grid-column: initial;
        flex-direction: column;
    }

    > * {
        flex: 1;

        @include max-width($tablet_wide) {
            flex: auto;
        }
    }

    > * + * {
        margin-left: 16px;

        @include max-width($tablet_wide) {
            margin-left: 0;
        }
    }

    img {
        height: 400px;

        @include max-width($tablet_wide) {
            height: 200px;
        }

        @include max-width($phone) {
            height: 170px;
        }
    }

    .h3 {
		@include fluid-text($phone, $desktop_large, 15px, 24px, 20px, 32px);
	    font-weight: 500;

        @include max-width($tablet_wide) {
            font: inherit;
        }
	}

    .news_content * {
        text-align: left !important;
    }

    .gs_link--colored {
        margin-top: auto;
    }
}
