.h1 {
	@include fluid-text($phone, $desktop_large, 24px, 36px, 28px, 46px);
	font-weight: 800;

	&--margin {
		margin-bottom: 32px;

		@include max-width($tablet) {
			margin-bottom: 16px;
		}
	}

	&--center {
		text-align: center;
	}
}

.h2 {
	@include fluid-text($phone, $desktop_large, 22px, 26px, 28px, 36px);
	font-weight: 500;

	&--margin {
		margin-bottom: 32px;
	
		@include max-width($tablet) {
			margin-bottom: 16px;
		}
	}

	&--center {
		text-align: center;
	}
}

.h3 {
	@include fluid-text($phone, $desktop_large, 15px, 22px, 20px, 30px);
	font-weight: 500;

	&--margin {
		margin-bottom: 32px;
	
		@include max-width($tablet) {
			margin-bottom: 16px;
		}
	}

	&--center {
		text-align: center;
	}
}

.header_with_link {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 32px;

	@include max-width($tablet) {
		margin-bottom: 16px;
	}
}
