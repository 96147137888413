.hamburger {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 64px;
    height: 64px;

    span {
        display: block;
        width: 24px;
        height: 1.5px;
        border-radius: $radius--medium;
        background-color: $olive;
        transition: transform $transition;
    }

    span + span {
        margin-top: 6px;
    }

    &.opened {
        span {
            width: 32px;
            margin: 0;
        }

        span:first-child {
            position: absolute;
            transform: rotate(45deg);
        }

        span:nth-child(2) {
            transition: none;
            opacity: 0;
        }

        span:last-child {
            position: absolute;
            transform: rotate(-45deg);
        }
    }
}
