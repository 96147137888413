.avatar {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $primary;
	border-radius: $radius--medium;
	width: 200px;
	height: 200px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		border-radius: $radius--medium;
	}

	@include max-width($phone) {
		width: 96px;
		height: 96px;
	}
}
