@keyframes fadeIn {
    from {
        opacity: .5;
    }
    to {
        opacity: 1;
    }
}

@keyframes rotate {
    0% {
		transform: rotate(0)
    }
	100% {
		transform: rotate(360deg);
	}
}

@keyframes slide {
    from {
        right: -300px;
        opacity: 0;
    }
    to {
        right: 0;
        opacity: 1;
    }
}
