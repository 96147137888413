.gs_label {
    display: inline-block;
    width: fit-content;
    border: 1px solid $pale;
    border-radius: $radius--medium;
    padding: 3px 8px;

    @include max-width($tablet) {
        border-radius: $radius--small;
        padding: 2px 5px;
    }
}

.gs_label--primary {
	background-color: $primary;
	border-color: $primary;
	color: white;
}

.gs_label--secondary {
    background-color: $secondary;
    border-color: $secondary;
    color: $olive;
}
