.gs_checkbox {
	position: relative;
	display: flex;

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 1px;
		height: 1px;
		margin: 0;
		opacity: 0;
	}

	input:checked + label::before,
	input:focus:not(:checked) + label::before,
	label:hover::before {
		background-color: $primary;
		border-color: $primary;
	}

	input:checked + label::after {
		opacity: 1;
	}

	input:disabled + label {
		pointer-events: none;
		color: #BFC7D7;
	}

	input:disabled + label::before {
		background-color: #BFC7D7;
	}

	label {
		cursor: pointer;
		position: relative;
		padding-left: 28px;
	}

	label::before,
	label::after {
		content: "";
		position: absolute;
		width: 20px;
		height: 20px;
        transform: translateY(-50%);
		top: 50%;
		left: 0;
		transition: $transition;
	}

	label::before {
		border: 1.5px solid #BFC7D7;
		border-radius: $radius--small;
	}

	label::after {
		background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 10L0 5.19231L1.4 3.84615L5 7.30769L12.6 0L14 1.34615L5 10Z' fill='white'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto;
		opacity: 0;
	}
}

.gs_checkbox--white {

	input:checked + label::before,
	input:focus:not(:checked) + label::before,
	label:hover::before {
		background-color: white;
		border-color: white;
	}

	label::before {
		border-color: $secondary;
	}

	label::after {
		background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 10L0 5.19231L1.4 3.84615L5 7.30769L12.6 0L14 1.34615L5 10Z' fill='%2327ACA1'/%3E%3C/svg%3E%0A");
	}
}
