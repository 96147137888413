.benefit_card {
	@extend .gs_card;
	display: flex;
	flex-direction: column;
	padding-left: 0;
	padding-right: 0;

	&__header {
		position: relative;
		display: flex;
		align-items: center;
		padding: 0 24px;
		height: 60px;

		@include max-width($tablet) {
			padding: 0 16px;
			height: 50px;
		}
	}

	&__track {
		@include background("data:image/svg+xml,%3Csvg width='512' height='58' viewBox='0 0 512 58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-0.290104 0L33.0519 57.75H-33.6321L-0.290104 0Z' fill='white'/%3E%3Cpath d='M42.3939 57.75L9.05188 0L75.7358 0L42.3939 57.75Z' fill='white'/%3E%3Cpath d='M85.0778 0L118.42 57.75H51.7358L85.0778 0Z' fill='white'/%3E%3Cpath d='M127.762 57.75L94.4198 0L161.104 0L127.762 57.75Z' fill='white'/%3E%3Cpath d='M170.446 0L203.788 57.75H137.104L170.446 0Z' fill='white'/%3E%3Cpath d='M213.13 57.75L179.788 0L246.472 0L213.13 57.75Z' fill='white'/%3E%3Cpath d='M255.814 0L289.156 57.75H222.472L255.814 0Z' fill='white'/%3E%3Cpath d='M298.498 57.75L265.156 0L331.84 0L298.498 57.75Z' fill='white'/%3E%3Cpath d='M341.182 0L374.524 57.75H307.84L341.182 0Z' fill='white'/%3E%3Cpath d='M383.866 57.75L350.524 0L417.208 0L383.866 57.75Z' fill='white'/%3E%3Cpath d='M426.549 0L459.891 57.75H393.208L426.549 0Z' fill='white'/%3E%3Cpath d='M469.233 57.75L435.891 0L502.575 0L469.233 57.75Z' fill='white'/%3E%3Cpath d='M511.917 0L545.259 57.75H478.575L511.917 0Z' fill='white'/%3E%3C/svg%3E", $repeat: repeat-x);
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		height: calc(100% - 22px);
		width: 100%;

		@include max-width($tablet_wide) {
			@include background("data:image/svg+xml,%3Csvg width='320' height='37' viewBox='0 0 320 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-0.181282 0.875L20.6575 36.9688H-21.02L-0.181282 0.875Z' fill='white'/%3E%3Cpath d='M26.4962 36.9688L5.65748 0.874996L47.335 0.874996L26.4962 36.9688Z' fill='white'/%3E%3Cpath d='M53.1737 0.875L74.0124 36.9688H32.335L53.1737 0.875Z' fill='white'/%3E%3Cpath d='M79.8512 36.9688L59.0125 0.874996L100.69 0.874996L79.8512 36.9688Z' fill='white'/%3E%3Cpath d='M106.529 0.875L127.367 36.9688H85.6899L106.529 0.875Z' fill='white'/%3E%3Cpath d='M133.206 36.9688L112.367 0.874996L154.045 0.874996L133.206 36.9688Z' fill='white'/%3E%3Cpath d='M159.884 0.875L180.722 36.9688H139.045L159.884 0.875Z' fill='white'/%3E%3Cpath d='M186.561 36.9688L165.722 0.874996L207.4 0.874996L186.561 36.9688Z' fill='white'/%3E%3Cpath d='M213.239 0.875L234.077 36.9688H192.4L213.239 0.875Z' fill='white'/%3E%3Cpath d='M239.916 36.9688L219.077 0.874996L260.755 0.874996L239.916 36.9688Z' fill='white'/%3E%3Cpath d='M266.593 0.875L287.432 36.9688H245.755L266.593 0.875Z' fill='white'/%3E%3Cpath d='M293.271 36.9688L272.432 0.874996L314.11 0.874996L293.271 36.9688Z' fill='white'/%3E%3Cpath d='M319.948 0.875L340.787 36.9688H299.11L319.948 0.875Z' fill='white'/%3E%3C/svg%3E", $repeat: repeat-x);
		}

		@include max-width($tablet) {
			height: calc(100% - 12px);
		}
	}

	&__icon {
		@extend .h2;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background-color: $primary;
		color: white;
		border-radius: 96px;
		width: 60px;
		height: 60px;
		z-index: 1;
		padding: 12px;

		@include max-width($tablet) {
			width: 50px;
			height: 50px;
		}
	}

	&__icon svg,
	&__icon img {
		width: 100%;
		height: 100%;
	}

	&__icon img {
		object-fit: contain;
	}

	&__number {
		width: auto;
		min-width: 60px;

		@include max-width($tablet) {
			min-width: 50px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-grow: 1;
		padding: 20px 24px 0;

		@include max-width($tablet) {
			padding: 12px 16px 0;
		}
	}

	&__content > * + * {
		margin-top: 12px;
	}
}
