.admin_text_block {

	.collapse {
		display: none;
	}

	hr {
		border-color: $primary;
		margin: 16px 0;

		@include max-width($tablet) {
			margin: 8px 0;
		}
	}

	h1 {
		@include fluid-text($phone, $desktop_large, 24px, 36px, 28px, 46px);
		font-weight: 800;
	}
	
	h2 {
		@include fluid-text($phone, $desktop_large, 22px, 26px, 28px, 36px);
		font-weight: 500;
	}
	
	h3 {
		@include fluid-text($phone, $desktop_large, 15px, 22px, 20px, 30px);
		font-weight: 500;
	}

	h1, h2, h3 {
		margin-bottom: 16px;
	}

	ul {
		list-style-type: disc;
		margin-left: 24px;
	}

	ol {
		list-style-type: decimal;
		margin-left: 24px;
	}

	li + li {
		margin-top: 8px;
	}

    img {
        width: 100%;
        height: auto;
		border-radius: $radius;
    }

	* + .gs_button {
		margin-top: 16px;
	}

	table {
		@extend .gs_table;
	}
}
