.gs_tabs {
	display: flex;

	&--full {
		width: 100%;
	}

	&--full &__item {
		width: 100%;
	}

	&__item {
		background-color: transparent;
		border: none;
		outline: none;
		color: $pale;
		text-align: center;
		border-bottom: 2px solid transparent;
		transition: $transition;
		padding-bottom: 4px;

		@include max-width($phone) {
			width: 100%;
		}
	}

	&__item + &__item {
		margin-left: 32px;

		@include max-width($tablet) {
			margin-left: 16px;
		}
	}

	&__item:hover,
	&__item:focus-visible {
		color: $primary;
	}

	&__item--large {
		@include fluid-text($phone, $desktop_large, 14px, 30px, 22px, 40px);
		font-weight: 500;
	}

	&__item--active {
		color: $primary;
		border-color: $primary;
		pointer-events: none;
	}
}
