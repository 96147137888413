.audience_card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    > * + * {
        margin-top: 8px;
    }

    img {
		width: 120px;
		height: 120px;
		object-fit: cover;
		object-position: center;
        border-radius: 50%;

        @include max-width($tablet_wide) {
            width: 96px;
            height: 96px;
        }
    }
}
