.active_event_card {
    @extend .gs_card;
	display: flex;
	flex-direction: column;
	min-height: 305px;

    @include max-width($tablet) {
		min-height: 205px;
	}

	> * + * {
		margin-top: 16px;
	}

    .h3 {
        margin-bottom: 16px;
    }

	&__header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	&__footer {
		display: flex;
		margin-top: auto;
	}

	&__header > * + *,
	&__footer > * + * {
		margin-left: 12px;
	}

    &__labels {
        display: flex;
        flex-wrap: wrap;
    }

    &__labels > * {
        margin-right: 6px;
        margin-bottom: 6px;
    }

	&__trash {
		flex-shrink: 0;
		outline: none;
		background-color: transparent;
	}

	&__trash svg {
		display: block;
	}

	&__trash path {
		transition: $transition;
	}

	&__trash:hover path,
	&__trash:focus-visible path {
		fill: $primary;
	}
}
