.event_speaker_card {
    @extend .gs_card;
    display: flex;

    @include max-width($tablet_wide) {
        flex-direction: column;
    }

    > * + * {
        margin-left: 32px;

        @include max-width($tablet_wide) {
            margin-left: 0;
            margin-top: 16px;
        }
    }

    img {
        width: 200px;
        height: 200px;
        border-radius: $radius--medium;
        flex-shrink: 0;

        @include max-width($tablet_wide) {
            width: 150px;
            height: 150px;
        }
    }

    .h3 {
        margin-right: 32px;

        @include max-width($tablet_wide) {
            margin-right: 0;
        }
    }

    &__description {
        flex: 0 0 50%;
        margin-left: auto;

        @include max-width($tablet_wide) {
            flex: auto;
            margin-left: 0;
        }
    }
}
