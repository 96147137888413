.gs_filter {
	outline: none;
	font-weight: 600;
	color: $primary;
	background-color: $secondary;
	border: 1px solid $primary;
	border-radius: 25px;
	padding: 8px 16px;
	transition: color $transition, background-color $transition, padding .1s ease;
	margin-right: 8px;
	margin-bottom: 8px;

	@include max-width($tablet) {
		padding: 6px 12px;
	}

    &:hover,
    &:focus-visible {
        color: $primary--dark;
    }

	&--active {
		@include background("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='28' height='28' rx='14' fill='%2327ACA1'/%3E%3Cpath d='M20.0458 9.4856C20.3299 9.78703 20.3158 10.2617 20.0144 10.5458L12.0846 18.0197C11.7483 18.3366 11.2204 18.3269 10.8961 17.9977L8.21576 15.2774C7.92504 14.9823 7.92856 14.5075 8.22361 14.2167C8.51867 13.926 8.99353 13.9295 9.28424 14.2246L11.5184 16.4921L18.9856 9.45421C19.287 9.17011 19.7617 9.18416 20.0458 9.4856Z' fill='white'/%3E%3C/svg%3E", $color: $secondary, $size: 28px 28px, $position: 9px center);
		padding-left: 46px;
		pointer-events: none;

		@include max-width($tablet) {
			@include background("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='10' fill='%2327ACA1'/%3E%3Cpath d='M16.0458 5.4856C16.3299 5.78703 16.3158 6.26169 16.0144 6.54579L8.08456 14.0197C7.74829 14.3366 7.22042 14.3269 6.89609 13.9977L4.21576 11.2774C3.92504 10.9823 3.92856 10.5075 4.22361 10.2167C4.51867 9.92602 4.99353 9.92954 5.28424 10.2246L7.51839 12.4921L14.9856 5.45421C15.287 5.17011 15.7617 5.18416 16.0458 5.4856Z' fill='white'/%3E%3C/svg%3E", $color: $secondary, $size: 20px 20px, $position: 8px center);
			padding-left: 36px;
		}
	}
}

.limited li:nth-child(n + 5) {
	@include max-width($tablet_wide) {
		display: none;
	}
}
