.marquee {
  overflow: hidden;
  width: calc(100% + 360px);
  margin-left: -180px;

  .js-marquee-wrapper {
    display: flex;
  }

  .js-marquee {
    margin-right: 0 !important;
  }

  ul {
    display: flex;
    align-items: center;
  }

  .js-marquee + .js-marquee,
  li + li {
    margin-left: 64px;

    @include max-width($phone) {
      margin-left: 32px;
    }
  }

  img {
    object-fit: contain;
    opacity: 0.85;
    transition: $transition;

    @include max-width($phone) {
      width: auto;
      height: 60px;
    }
  }

  a {
    outline: none;
  }

  a:hover img,
  a:focus-visible img {
    opacity: 1;
  }
}
