.jquery-modal {
	z-index: 99;
	background-color: $background_overlay;
}

.bg--login {
	@include background("/static/build/img/login_bg.jpg", $size: cover);

	@include max-width($tablet) {
		@include background("/static/build/img/login_bg--tablet.jpg", $size: cover);
	}
}

.bg--white {
	background-color: white;
}

.bg--pale {
	background-color: $pale--tertiary;
}

.bg--wide .gs_modal {
	width: 784px;
}

.gs_modal {
	width: 515px;
	max-width: 100%;
	padding: 0;
	outline: none;
	box-shadow: none;
	background-color: transparent;

	&__close {
		@include background("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 13L35.6274 35.6274' stroke='%235D647D' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M35.627 13L12.9995 35.6274' stroke='%235D647D' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		width: 38px;
		height: 38px;
	}

	&__close--alt {
		@include background("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 13L35.6274 35.6274' stroke='%2327ACA1' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M35.6289 13L13.0015 35.6274' stroke='%2327ACA1' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		width: 60px;
		height: 60px;
		background-color: white;
		border-radius: $radius--medium;

		@include max-width($tablet) {
			width: 48px;
			height: 48px;
		}
	}

	&__top {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 48px;

		@include max-width($tablet) {
			margin-bottom: 20px;
		}
	}

	&__top img {
		display: block;
	}
	
	&__inner {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		box-shadow: none;
		border-radius: $radius;
		padding: 24px;
		background-color: white;

		@include max-width($tablet) {
			padding: 16px;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__body {
		display: flex;
		flex-direction: column;
	}

	&__inner > * + *,
	&__inner .gs_form > * + *,
	&__body > * + * {
		margin-top: 32px;

		@include max-width($tablet) {
			margin-top: 16px;
		}
	}

	.gs_form > [type="hidden"] + * {
		margin-top: 0;
	}

	&__footer {
		display: flex;
		flex-direction: column;
	}

	&__footer > * + * {
		margin-top: 12px;
	}
}
