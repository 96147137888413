.paginationjs {
	font-family: inherit;

	@include max-width($phone) {
		justify-content: center;
	}

	.paginationjs-pages ul {
		display: flex;
		align-items: center;
	}
	
	.paginationjs-pages ul li {
		float: initial;
		border: none !important;
		border-radius: 0 !important;
		margin: 0 8px;

		@include max-width($phone) {
			margin: 0 4px;
		}

		@include max-width($phone_small) {
			margin: 0 2px;
		}
	}
	
	.paginationjs-pages li a {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		width: 32px !important;
		height: 32px !important;
		font-size: 14px;
		line-height: 1;
		color: inherit;
		background: $background;
		border: 1px solid $pale--secondary;
		border-radius: 50% !important;
		transition: $transition;
	}

	.paginationjs-pages li a:hover {
		background: none;
		border-color: $primary;
	}

	.paginationjs-pages li.active a {
		background: $background;
		border-color: $primary;
		color: inherit;
	}

	.paginationjs-pages li.disabled a {
		pointer-events: none;
	}

	.paginationjs-pages .paginationjs-prev a,
	.paginationjs-pages .paginationjs-next a {
		background-image: url("data:image/svg+xml,%3Csvg width='6' height='12' viewBox='0 0 6 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.132584 0.913238C-0.0682506 0.687902 -0.0506176 0.342892 0.173181 0.140247C0.397423 -0.0615773 0.743526 -0.0434317 0.945146 0.180845L5.86679 5.6496C6.05368 5.85891 6.05368 6.17278 5.86679 6.38226L0.945896 11.8192C0.744071 12.0434 0.398105 12.0616 0.173932 11.8598C-0.0624766 11.6455 -0.0578632 11.2997 0.133334 11.0868L4.72439 6.01525L0.132584 0.913238Z' fill='black'/%3E%3C/svg%3E%0A") !important;
		background-repeat: no-repeat !important;
		background-position: center !important;
		background-size: auto !important;
		font-size: 0 !important;
	}

	.paginationjs-pages .paginationjs-prev a {
		transform: scaleX(-1);
	}
}
