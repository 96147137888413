.gs_form {

	input,
	textarea,
	select {
		outline: none;
		width: 100%;
		background-color: white;
		border: 1px solid $pale--secondary;
		border-radius: $radius--medium;
		padding: 16px;
		transition: border $transition;

		@include max-width($tablet) {
			padding: 14px 16px;
		}
	}

	&__group {
		position: relative;
	}

	&__group label {
		position: absolute;
		top: 6px;
		left: 16px;
		color: $pale;
		pointer-events: none;
		transition: $transition;
		font-size: 12px;
		line-height: 16px;

		@include max-width($tablet) {
			top: 4px;
		}
	}

	&__group--textarea::before {
		content: "";
		position: absolute;
		top: 1px;
		left: 1px;
		width: calc(100% - 2px);
		height: 25px;
		background-color: white;
		border-radius: $radius--medium $radius--medium 0 0;
	}

	&__group--required::before {
		content: "";
		position: absolute;
		top: -4px;
		left: 16px;
		width: 8px;
		height: 8px;
		background: white url("data:image/svg+xml,%3Csvg width='7' height='8' viewBox='0 0 7 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.056 1.778L6.866 3.218L4.4 4.136L6.866 5.054L6.038 6.53L3.986 4.91L4.364 7.52H2.708L3.05 4.91L0.998 6.566L0.134 5.036L2.582 4.118L0.134 3.236L0.962 1.76L3.068 3.38L2.708 0.751999H4.382L3.986 3.38L6.056 1.778Z' fill='%23EA4C4C'/%3E%3C/svg%3E") no-repeat center / contain;
	}

	&__group--password input {
		padding-right: 42px;
	}

	&__group input,
	&__group textarea {
		padding: 24px 16px 8px;
	}

	&__group:not(:has(label)) input,
	&__group:not(:has(label)) textarea {
		padding: 8px 16px;
	}

	&__group input:placeholder-shown,
	&__group textarea:placeholder-shown {
		padding: 16px;
	}

	&__group input:placeholder-shown + label,
	&__group textarea:placeholder-shown + label {
		position: absolute;
		transform: translateY(-50%);
		top: 30px;
		font-size: inherit;
		line-height: inherit;

		@include max-width($tablet) {
			top: 24px;
		}
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background-color: transparent;
		background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6L8.00022 11L13 6.00043' stroke='%230C0C3D' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-size: 24px;
		background-position-y: center;
		background-position-x: calc(100% - 16px);
		padding-right: 42px;

		@include max-width($phone) {
			background-size: 16px;
		}
	}

	option {
		color: $pale;
	}

	input {
		height: 60px;

		@include max-width($tablet) {
			height: 50px;
		}
	}

	textarea {
		min-height: 105px;
	}

	input::placeholder,
	textarea::placeholder {
		visibility: hidden;
		opacity: 0;
	}

	input:focus-visible,
	textarea:focus-visible,
	input:focus,
	textarea:focus {
		border-color: $primary;
	}

	input:disabled {
		background-color: $pale--tertiary;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:focus {
		-webkit-box-shadow: 0 0 0 50px $secondary inset;
		-webkit-text-fill-color: $olive;
	}

	fieldset {
		display: flex;
		flex-direction: column;
	}

	fieldset > * + *,
	fieldset + p {
		margin-top: 12px;
	}

	.errors {
		color: $red;
		font-size: 12px;
		line-height: 16px;
	}

	.errors p {
		margin-bottom: 6px;
	}

	.gs_modal__body .errors + * {
		margin-top: 0;
	}

	@-moz-document url-prefix() {
		.gs_form__group--textarea::before {
			top: 2px;
		}
	}
}

input[type='search'] {
	outline: none;
	width: 100%;
	background-color: white;
	border: 1px solid $pale--secondary;
	border-radius: $radius--medium;
	padding: 16px;
	transition: border $transition;

	@include max-width($tablet) {
		padding: 14px 16px;
	}
}

input[type='search']::-webkit-search-cancel-button {
	display: none;
}

input[type='search']:focus-visible,
input[type='search']:focus {
	border-color: $primary;
}
