.d_flex {
    display: flex;
}

.flex_equal > * {
	flex: 1;
}

.no_shrink {
	flex-shrink: 0;
}

.flex_row {
    flex-direction: row;
}

.flex_row_reverse {
    flex-direction: row-reverse;
}

.flex_column {
    flex-direction: column;
}

.flex_column_reverse {
    flex-direction: column-reverse;
}

.justify_content_start {
    justify-content: flex-start;
}

.justify_content_end {
    justify-content: flex-end;
}

.justify_content_center {
    justify-content: center;
}

.justify_content_between {
    justify-content: space-between;
}

.justify_content_around {
    justify-content: space-around;
}

.align_items_start {
    align-items: flex-start;
}

.align_items_center {
    align-items: center;
}

.align_items_end {
    align-items: flex-end;
}

.align_items_stretch {
    align-items: stretch;
}

.flex_wrap {
    flex-wrap: wrap;
}


.flex_row--desktop_large {
	@include max-width($desktop_large) {
		flex-direction: row;
	}
}

.flex_row_reverse--desktop_large {
	@include max-width($desktop_large) {
		flex-direction: row-reverse;
	}
}

.flex_column--desktop_large {
	@include max-width($desktop_large) {
		flex-direction: column;
	}
}

.flex_column_reverse--desktop_large {
	@include max-width($desktop_large) {
		flex-direction: column-reverse;
	}
}

.justify_content_start--desktop_large {
	@include max-width($desktop_large) {
		justify-content: flex-start;
	}
}

.justify_content_end--desktop_large {
	@include max-width($desktop_large) {
		justify-content: flex-end;
	}
}

.justify_content_center--desktop_large {
	@include max-width($desktop_large) {
		justify-content: center;
	}
}

.justify_content_between--desktop_large {
	@include max-width($desktop_large) {
		justify-content: space-between;
	}
}

.justify_content_around--desktop_large {
	@include max-width($desktop_large) {
		justify-content: space-around;
	}
}

.align_items_start--desktop_large {
	@include max-width($desktop_large) {
		align-items: flex-start;
	}
}

.align_items_center--desktop_large {
	@include max-width($desktop_large) {
		align-items: center;
	}
}

.align_items_end--desktop_large {
	@include max-width($desktop_large) {
		align-items: flex-end;
	}
}

.flex_wrap--desktop_large {
	@include max-width($desktop_large) {
		flex-wrap: wrap;
	}
}


.flex_row--desktop {
	@include max-width($desktop) {
		flex-direction: row;
	}
}

.flex_row_reverse--desktop {
	@include max-width($desktop) {
		flex-direction: row-reverse;
	}
}

.flex_column--desktop {
	@include max-width($desktop) {
		flex-direction: column;
	}
}

.flex_column_reverse--desktop {
	@include max-width($desktop) {
		flex-direction: column-reverse;
	}
}

.justify_content_start--desktop {
	@include max-width($desktop) {
		justify-content: flex-start;
	}
}

.justify_content_end--desktop {
	@include max-width($desktop) {
		justify-content: flex-end;
	}
}

.justify_content_center--desktop {
	@include max-width($desktop) {
		justify-content: center;
	}
}

.justify_content_between--desktop {
	@include max-width($desktop) {
		justify-content: space-between;
	}
}

.justify_content_around--desktop {
	@include max-width($desktop) {
		justify-content: space-around;
	}
}

.align_items_start--desktop {
	@include max-width($desktop) {
		align-items: flex-start;
	}
}

.align_items_center--desktop {
	@include max-width($desktop) {
		align-items: center;
	}
}

.align_items_end--desktop {
	@include max-width($desktop) {
		align-items: flex-end;
	}
}

.flex_wrap--desktop {
	@include max-width($desktop) {
		flex-wrap: wrap;
	}
}


.flex_row--tablet_wide {
	@include max-width($tablet_wide) {
		flex-direction: row;
	}
}

.flex_row_reverse--tablet_wide {
	@include max-width($tablet_wide) {
		flex-direction: row-reverse;
	}
}

.flex_column--tablet_wide {
	@include max-width($tablet_wide) {
		flex-direction: column;
	}
}

.flex_column_reverse--tablet_wide {
	@include max-width($tablet_wide) {
		flex-direction: column-reverse;
	}
}

.justify_content_start--tablet_wide {
	@include max-width($tablet_wide) {
		justify-content: flex-start;
	}
}

.justify_content_end--tablet_wide {
	@include max-width($tablet_wide) {
		justify-content: flex-end;
	}
}

.justify_content_center--tablet_wide {
	@include max-width($tablet_wide) {
		justify-content: center;
	}
}

.justify_content_between--tablet_wide {
	@include max-width($tablet_wide) {
		justify-content: space-between;
	}
}

.justify_content_around--tablet_wide {
	@include max-width($tablet_wide) {
		justify-content: space-around;
	}
}

.align_items_start--tablet_wide {
	@include max-width($tablet_wide) {
		align-items: flex-start;
	}
}

.align_items_center--tablet_wide {
	@include max-width($tablet_wide) {
		align-items: center;
	}
}

.align_items_end--tablet_wide {
	@include max-width($tablet_wide) {
		align-items: flex-end;
	}
}

.flex_wrap--tablet_wide {
	@include max-width($tablet_wide) {
		flex-wrap: wrap;
	}
}


.flex_row--tablet {
	@include max-width($tablet) {
		flex-direction: row;
	}
}

.flex_row_reverse--tablet {
	@include max-width($tablet) {
		flex-direction: row-reverse;
	}
}

.flex_column--tablet {
	@include max-width($tablet) {
		flex-direction: column;
	}
}

.flex_column_reverse--tablet {
	@include max-width($tablet) {
		flex-direction: column-reverse;
	}
}

.justify_content_start--tablet {
	@include max-width($tablet) {
		justify-content: flex-start;
	}
}

.justify_content_end--tablet {
	@include max-width($tablet) {
		justify-content: flex-end;
	}
}

.justify_content_center--tablet {
	@include max-width($tablet) {
		justify-content: center;
	}
}

.justify_content_between--tablet {
	@include max-width($tablet) {
		justify-content: space-between;
	}
}

.justify_content_around--tablet {
	@include max-width($tablet) {
		justify-content: space-around;
	}
}

.align_items_start--tablet {
	@include max-width($tablet) {
		align-items: flex-start;
	}
}

.align_items_center--tablet {
	@include max-width($tablet) {
		align-items: center;
	}
}

.align_items_end--tablet {
	@include max-width($tablet) {
		align-items: flex-end;
	}
}

.flex_wrap--tablet {
	@include max-width($tablet) {
		flex-wrap: wrap;
	}
}


.flex_row--phone {
	@include max-width($phone) {
		flex-direction: row;
	}
}

.flex_row_reverse--phone {
	@include max-width($phone) {
		flex-direction: row-reverse;
	}
}

.flex_column--phone {
	@include max-width($phone) {
		flex-direction: column;
	}
}

.flex_column_reverse--phone {
	@include max-width($phone) {
		flex-direction: column-reverse;
	}
}

.justify_content_start--phone {
	@include max-width($phone) {
		justify-content: flex-start;
	}
}

.justify_content_end--phone {
	@include max-width($phone) {
		justify-content: flex-end;
	}
}

.justify_content_center--phone {
	@include max-width($phone) {
		justify-content: center;
	}
}

.justify_content_between--phone {
	@include max-width($phone) {
		justify-content: space-between;
	}
}

.justify_content_around--phone {
	@include max-width($phone) {
		justify-content: space-around;
	}
}

.align_items_start--phone {
	@include max-width($phone) {
		align-items: flex-start;
	}
}

.align_items_center--phone {
	@include max-width($phone) {
		align-items: center;
	}
}

.align_items_end--phone {
	@include max-width($phone) {
		align-items: flex-end;
	}
}

.flex_wrap--phone {
	@include max-width($phone) {
		flex-wrap: wrap;
	}
}
