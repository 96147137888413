.documents_block {
	width: 60%;
	
	@include max-width($desktop) {
		width: 100%;
	}
	
	.slider-wrapper {
		width: 100%;
		margin-left: 0;

		@include max-width($phone) {
			width: calc(100% + 40px);
			margin-left: -20px;
		}
	}
	
	.swiper {
		width: calc(100% - 50px);
	}
}

.document_wrapper {
	@extend .gs_card;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px;

	picture {
		border: 1px solid $pale--secondary;
		border-radius: $radius--medium;
		margin: 0;
	}

	img {
		width: 100%;
		border-radius: $radius--medium;

		@include max-width($desktop) {
			max-width: 350px;
			// max-height: 375px;
		}
	}

	> * + * {
		margin-top: 8px;
	}

	.gs_link--stretched:hover ~ .gs_link,
	.gs_link--stretched:focus-visible ~ .gs_link {
		color: $primary--hover;
	}

	.gs_link--stretched:active ~ .gs_link {
		color: $primary--dark;
	}
}
