html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-rendering: optimizeSpeed;
  scrollbar-gutter: stable;
  scroll-padding-top: 100px;
}

body {
  position: relative;
  font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  @include fluid-text($phone, $desktop_large, 14px, 16px, 20px, 26px);
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
  background-color: white;
  color: $olive;
  min-width: 320px;
  min-height: 100vh;
  overflow-x: hidden;

  &.blocked {
    overflow: hidden;
    max-height: 100vh;
  }
}

.shadow {
  pointer-events: none;

  @include max-width($tablet_wide) {
    background-color: $background_overlay;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 8;
    transition: $transition;

    &.visible {
      pointer-events: initial;
      opacity: 1;
    }
  }
}

::selection {
  background-color: $primary;
  color: white;
}

[data-light-theme] {
  color: white;
}

.main_wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main_content {
  flex: 1;
  animation: fadeIn 0.75s ease;
  overflow-x: hidden;
}

.wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1360px;
  padding: 0 60px;

  @include max-width($desktop) {
    padding: 0 40px;
  }

  @include max-width($tablet_wide) {
    padding: 0 20px;
  }
}

.wrapper--medium {
  max-width: 1056px;
  padding: 0;

  @include max-width(1096px) {
    max-width: 100%;
    padding: 0 20px;
  }
}

.wrapper--small {
  max-width: 784px;
  padding: 0;

  @include max-width(824px) {
    max-width: 100%;
    padding: 0 20px;
  }
}

.section {
  position: relative;
  margin: 80px 0;

  @include max-width($desktop) {
    margin: 60px 0;
  }

  @include max-width($tablet) {
    margin: 40px 0;
  }
}

.section--start {
  position: relative;
  margin-bottom: 80px;

  @include max-width($desktop) {
    margin-bottom: 60px;
  }

  @include max-width($tablet) {
    margin-bottom: 40px;
  }
}

.section--end {
  position: relative;
  margin-top: 80px;

  @include max-width($desktop) {
    margin-top: 60px;
  }

  @include max-width($tablet) {
    margin-top: 40px;
  }
}

.intro_overlay {
  position: relative;
  margin-top: -185px;
  padding-top: calc(185px + 80px);
  padding-bottom: 80px;
  min-height: 800px;

  @include max-width($tablet_wide) {
    margin-top: 0;
    padding-top: 60px;
    padding-bottom: 60px;
    min-height: 460px;
  }

  @include max-width($tablet) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@for $i from 1 through 4 {
  .ellipsis--#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: #{$i};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

p + p,
p + ul,
p + ol,
ul + p,
ol + p {
  margin-top: 10px;
}
