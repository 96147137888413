.search_panel {
    position: relative;
    flex: 1;
    
    input[type='search'] {
        width: 100%;
        min-width: 50px;
        background-color: transparent;
        border: 1px solid $pale--secondary;
        border-radius: $radius--medium;
        padding: 9px 12px 9px 40px;
        transition: $transition;
        outline: none;
    }

    input[type='search']:-webkit-search-cancel-button {
        appearance: none !important;
        -webkit-appearance: none !important;
    }

    input[type='search']:-webkit-autofill,
	input[type='search']:-webkit-autofill:focus {
		-webkit-box-shadow: 0 0 0 50px $secondary inset;
		-webkit-text-fill-color: $olive;
	}

    input[type='search']::placeholder {
        color: $pale;
    }

    input[type='search']:focus-visible {
        border-color: $primary--hover;
    }

    button[type='submit'] {
        @include background("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13Z' stroke='%235D647D' stroke-width='1.2' stroke-linejoin='round'/%3E%3Cpath d='M11.5 11.5L16 16' stroke='%235D647D' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A", $position: 15px center);
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 100%;
        border: none;
        outline: none;
        padding: 0;
        font-size: 0;
    }
}
