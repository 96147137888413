.event_card {
	@extend .gs_card;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__header {
		margin-bottom: 64px;

		@include max-width($tablet_wide) {
			margin-bottom: 40px;
		}
	}

	&__speakers {
		display: flex;
		flex-direction: column;
		margin-top: auto;
		margin-bottom: 24px;

		@include max-width($tablet_wide) {
			margin-bottom: 15px;
		}
	}

	&__speakers li {
		display: flex;
	}

	&__speakers > li + li {
		margin-top: 8px;
	}

	&__speakers img {
		width: 100px;
		height: 100px;
		border-radius: $radius--medium;
		margin-right: 16px;

		@include max-width($tablet_wide) {
			width: 64px;
			height: 64px;
			border-radius: 5px;
			margin-right: 10px;
		}
	}

	.gs_label {
		margin-bottom: 16px;
	}

	.gs_link {
		border: 1px solid transparent;
		border-radius: $radius;
	}

	.gs_link:hover,
	.gs_link:focus-visible {
		border-color: $pale--secondary;
	}
}

.event_card--promo {
	justify-content: flex-start;

	.gs_label,
	.event_card__speakers {
		margin-bottom: 0;
	}

	.gs_label + .event_card__speakers {
		margin-top: 16px;
	}
}
