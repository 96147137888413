.gs_radio {
	position: relative;
	display: flex;
	align-items: center;
	padding: 16px;

	@include max-width($phone) {
		padding: 10px;
	}

	> *:not(label) {
		pointer-events: none;
		z-index: 1;
	}

	> * + * {
		margin-left: 8px;
	}

	> *:last-child {
		margin-left: auto;
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 1px;
		height: 1px;
		margin: 0;
		opacity: 0;
	}

	input:checked + label::before,
	input:focus-visible:not(:checked) + label::before,
	label:hover::before {
		opacity: 1;
	}

	input:checked + label,
	input:focus-visible:not(:checked) + label,
	label:hover {
		border-color: #52BDB4;
	}

	input:checked ~ .circle {
		background-color: white;
	}

	input:disabled + label {
		pointer-events: none;
		background-color: #BFC7D7;
	}

	input:disabled + label::before {
		display: none;
	}

	input:disabled ~ .circle::before {
		background-color: transparent;
	}

	label {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: $radius--medium;
		border: 1px solid $secondary;
		transition: $transition;
		cursor: pointer;
	}

	label::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border-radius: $radius--medium;
		background-color: #52BDB4;
		transition: $transition;
		opacity: 0;
	}

	.circle {
		flex-shrink: 0;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 2px solid white;
		transition: $transition;

		@include max-width($phone) {
			width: 15px;
			height: 15px;
		}
	}

	.circle::before {
		content: "";
		position: relative;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: $primary;

		@include max-width($phone) {
			width: 6px;
			height: 6px;
		}
	}
}
