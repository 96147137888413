.knowledgebase_card {
	position: relative;
	display: flex;
	flex-direction: column;

	> * + * {
		margin-top: 8px;
	}

	.gs_link--stretched + * {
		margin-top: 0;
	}

	img {
		border: 1px solid $pale--tertiary;
		object-fit: cover;
		object-position: left;
		border-radius: $radius;
		width: 100%;
		height: 200px;

		@include max-width($phone) {
			height: 170px;
		}
	}

	.h3 {
		font: inherit;
		transition: $transition;
	}

	.gs_link--stretched:hover ~ .h3,
	.gs_link--stretched:focus-visible ~ .h3 {
		color: $primary--dark;
	}

	&--centered img {
		object-position: center;
	}
}
