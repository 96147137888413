
.gs_subscription {
	background-color: $background;
	border-radius: $radius;

	&__form {
		padding: 24px;

		@include max-width($tablet) {
			padding: 16px;
		}
	}

	&__image {
		border-left: 1px solid $pale--secondary;
		padding: 0 24px 24px;

		@include max-width($tablet_wide) {
			border-top: 1px solid $pale--secondary;
			border-left: none;
		}

		@include max-width($tablet) {
			padding: 0 16px 16px;
		}

		@include max-width($phone) {
			padding: 16px;
		}
	}

	&__image img {
		width: 100%;
		max-width: 500px;
	}
}
