.gs_table {
	display: table;

	@include max-width($tablet) {
		display: inline-table;
		overflow-x: auto;
	}

	tr {
		border-top: 2px solid $primary;
	}

	td {
		vertical-align: top;
		min-width: 200px;
	}

	th,
	td {
		padding: 16px 8px;
	}
}

.gs_table--short {
	tr:nth-child(n + 6) {
		display: none;
	}
}
