.d_grid {
    display: grid;
	grid-gap: 24px;

	@include max-width($tablet_wide) {
		grid-gap: 16px;
	}
}

@for $i from 1 through 4 {
	.grid_columns_#{$i} {
		grid-template-columns: repeat(#{$i}, 1fr);
	}
}

@for $i from 1 through 4 {
	.grid_columns_#{$i}--desktop_large {
		@include max-width($desktop_large) {
			grid-template-columns: repeat(#{$i}, 1fr);
		}
	}
}

@for $i from 1 through 4 {
	.grid_columns_#{$i}--desktop {
		@include max-width($desktop) {
			grid-template-columns: repeat(#{$i}, 1fr);
		}
	}
}

@for $i from 1 through 4 {
	.grid_columns_#{$i}--tablet_wide {
		@include max-width($tablet_wide) {
			grid-template-columns: repeat(#{$i}, 1fr);
		}
	}
}

@for $i from 1 through 4 {
	.grid_columns_#{$i}--tablet {
		@include max-width($tablet) {
			grid-template-columns: repeat(#{$i}, 1fr);
		}
	}
}

@for $i from 1 through 4 {
	.grid_columns_#{$i}--phone {
		@include max-width($phone) {
			grid-template-columns: repeat(#{$i}, 1fr);
		}
	}
}
