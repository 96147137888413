// ==> appear behaviour
*[data-mobile-appear-type="block"] {
    display: none !important;

    @include max-width($phone) {
        display: block !important;
    }
}

*[data-mobile-appear-type="flex"] {
    display: none !important;

    @include max-width($phone) {
        display: flex !important;
    }
}

*[data-tablet-appear-type="block"] {
    display: none !important;

    @include max-width($tablet) {
        display: block !important;
    }
}

*[data-tablet-appear-type="flex"] {
    display: none !important;

    @include max-width($tablet) {
        display: flex !important;
    }
}

*[data-tablet-wide-appear-type="block"] {
    display: none !important;

    @include max-width($tablet_wide) {
        display: block !important;
    }
}

*[data-tablet-wide-appear-type="flex"] {
    display: none !important;

    @include max-width($tablet_wide) {
        display: flex !important;
    }
}

*[data-desktop-appear-type="block"] {
    display: none !important;

    @include max-width($desktop) {
        display: block !important;
    }
}

*[data-desktop-appear-type="flex"] {
    display: none !important;

    @include max-width($desktop) {
        display: flex !important;
    }
}


// ==> disappear behaviour
*[data-mobile-disappear] {
    @include max-width($phone) {
        display: none !important;
    }
}

*[data-tablet-disappear] {
    @include max-width($tablet) {
        display: none !important;
    }
}

*[data-tablet-wide-disappear] {
    @include max-width($tablet_wide) {
        display: none !important;
    }
}

*[data-desktop-disappear] {
    @include max-width($desktop) {
        display: none !important;
    }
}
