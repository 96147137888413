.legal_service_card {
    @extend .gs_card;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .5s ease;

    &.active {
        background: $primary;
        color: white;
    }

    > * + * {
        margin-top: 32px;
    
        @include max-width($tablet) {
            margin-top: 16px;
        }
    }

    input[type="hidden"] + * {
		margin-top: 0;
	}
}
