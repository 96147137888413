.gs_activity {
	@extend .gs_card;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;

	@include max-width($tablet) {
		flex-direction: column;
		align-items: flex-start;
	}

	> * + * {
		margin-left: 16px;

		@include max-width($tablet) {
			margin-left: 0;
			margin-top: 16px;
		}
	}

	.gs_link--stretched + * {
		margin-left: 0;
		margin-top: 0;
	}
	
	.gs_button {
		@include max-width($tablet) {
			width: 100%;
		}
	}

	svg {
		@include max-width($phone) {
			width: 24px;
			height: 24px;
		}
	}

	path {
		transition: $transition;
	}

	.gs_link--stretched:hover ~ .gs_button,
	.gs_link--stretched:focus-visible ~ .gs_button {
		color: $primary--dark;
	}

	.gs_link--stretched:active ~ .gs_button {
		color: $olive;
	}

	.gs_link--stretched:hover ~ .d_flex path,
	.gs_link--stretched:focus-visible ~ .d_flex path {
		fill: $primary--dark;
	}

	.gs_link--stretched:active ~ .d_flex path {
		fill: $olive;
	}
}
