@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}

*::before,
*::after {
  pointer-events: none;
}

ul,
ol {
  list-style: none;
  padding: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

hr {
  width: 100%;
  border: none;
  border-top: 1px solid #DCF7F4;
}

img {
  display: block;
  user-select: none;
  -webkit-user-drag: none;
  max-width: 100%;
}

iframe {
  display: block;
  width: 100%;
}

address {
  font-style: normal;
}

input,
button,
textarea,
select {
  font: inherit;
}

textarea {
  display: block;
  resize: none;
}

button {
  cursor: pointer;
  padding: 0;
  border: none;
}

a {
  text-decoration-skip-ink: auto;
  text-decoration: none;
  color: inherit;
}

svg {
  vertical-align: middle;
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  text-align: left;
  font-weight: 500;
}

td {
  vertical-align: top;
}

/* Удаляем все анимации и переходы для людей, которые предпочитают их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes slide {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-rendering: optimizeSpeed;
  scrollbar-gutter: stable;
  scroll-padding-top: 100px;
}

body {
  position: relative;
  font-family: "Inter", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
  background-color: white;
  color: #0C0C3D;
  min-width: 320px;
  min-height: 100vh;
  overflow-x: hidden;
}
body {
  font-size: 14px;
  line-height: 20px;
}
@media screen and (min-width: 500px) {
  body {
    font-size: calc(14px + 2 * ((100vw - 500px) / 940));
    line-height: calc(20px + 6 * ((100vw - 500px) / 940));
  }
}
@media screen and (min-width: 1440px) {
  body {
    font-size: 16px;
    line-height: 26px;
  }
}
body.blocked {
  overflow: hidden;
  max-height: 100vh;
}

.shadow {
  pointer-events: none;
}
@media screen and (max-width: 1024px) {
  .shadow {
    background-color: rgba(6, 6, 37, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 8;
    transition: 0.25s ease;
  }
  .shadow.visible {
    pointer-events: initial;
    opacity: 1;
  }
}

::selection {
  background-color: #27ACA1;
  color: white;
}

[data-light-theme] {
  color: white;
}

.main_wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main_content {
  flex: 1;
  animation: fadeIn 0.75s ease;
  overflow-x: hidden;
}

.wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1360px;
  padding: 0 60px;
}
@media screen and (max-width: 1200px) {
  .wrapper {
    padding: 0 40px;
  }
}
@media screen and (max-width: 1024px) {
  .wrapper {
    padding: 0 20px;
  }
}

.wrapper--medium {
  max-width: 1056px;
  padding: 0;
}
@media screen and (max-width: 1096px) {
  .wrapper--medium {
    max-width: 100%;
    padding: 0 20px;
  }
}

.wrapper--small {
  max-width: 784px;
  padding: 0;
}
@media screen and (max-width: 824px) {
  .wrapper--small {
    max-width: 100%;
    padding: 0 20px;
  }
}

.section {
  position: relative;
  margin: 80px 0;
}
@media screen and (max-width: 1200px) {
  .section {
    margin: 60px 0;
  }
}
@media screen and (max-width: 768px) {
  .section {
    margin: 40px 0;
  }
}

.section--start {
  position: relative;
  margin-bottom: 80px;
}
@media screen and (max-width: 1200px) {
  .section--start {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  .section--start {
    margin-bottom: 40px;
  }
}

.section--end {
  position: relative;
  margin-top: 80px;
}
@media screen and (max-width: 1200px) {
  .section--end {
    margin-top: 60px;
  }
}
@media screen and (max-width: 768px) {
  .section--end {
    margin-top: 40px;
  }
}

.intro_overlay {
  position: relative;
  margin-top: -185px;
  padding-top: 265px;
  padding-bottom: 80px;
  min-height: 800px;
}
@media screen and (max-width: 1024px) {
  .intro_overlay {
    margin-top: 0;
    padding-top: 60px;
    padding-bottom: 60px;
    min-height: 460px;
  }
}
@media screen and (max-width: 768px) {
  .intro_overlay {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.ellipsis--1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis--2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis--3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis--4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

p + p,
p + ul,
p + ol,
ul + p,
ol + p {
  margin-top: 10px;
}

html {
  opacity: 1 !important;
  display: initial !important;
}

#specialButton {
  cursor: pointer;
  width: 32px;
  height: auto;
}
@media screen and (max-width: 500px) {
  #specialButton {
    margin: 0 10px;
  }
}

@media screen and (max-width: 500px) {
  #specialButton ~ .gs_button {
    padding: 9px;
  }
}

.special body {
  margin-top: 0 !important;
  margin-bottom: 100px !important;
}

.special .shadow {
  background-color: rgba(6, 6, 37, 0.5) !important;
}

.special .jquery-modal {
  background: rgba(6, 6, 37, 0.5) !important;
}

.special .gs_modal {
  background: none !important;
}

.special .gs_modal .gs_modal__top,
.special .gs_modal .gs_modal__top * {
  background: none !important;
}

.special .intro_overlay::before {
  display: none !important;
}

.special .intro_content,
.special .intro_content * {
  background: none !important;
}

.special .picture--1,
.special .picture--2,
.special .picture--3 {
  display: none !important;
}

.special .gs_link--stretched {
  background: none !important;
}

.special .gs_top_button {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 16L11.9997 8L5 15.9993' stroke='%230C0C3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: auto !important;
  background-color: white !important;
  bottom: 100px;
}

.special .hamburger * {
  background: black !important;
}

.special.special-color-2 .hamburger * {
  background: white !important;
}

.special .gs_button,
.special .index--grand_class .grand_class_card,
.special .partnership--feedback .feedback_card,
.special .event--documents .consultation_card,
.special .partnership_card .gs_card,
.special .partnership_card .package_card,
.special .partnership_card .promo_benefit_card,
.special .partnership_card .faq_card,
.special .partnership_card .person_card,
.special .partnership_card .event_speaker_card,
.special .partnership_card .service_card,
.special .partnership_card .event_card,
.special .partnership_card .legal_service_card,
.special .partnership_card .user_document,
.special .partnership_card .gs_activity,
.special .partnership_card .gs_education_step,
.special .partnership_card .call_to_action,
.special .partnership_card .document_wrapper,
.special .partnership_type,
.special .invitation_card,
.special .active_event_card,
.special .promotion_card,
.special .benefit_card,
.special .payment_card,
.special .application {
  background: none !important;
  border: 1px solid black !important;
}

.special .application .payment_card {
  border: none !important;
}

.special .event--documents .consultation_card::before {
  display: none !important;
}

.special.special-color-2 .active_event_card,
.special.special-color-2 .gs_button {
  border: 1px solid white !important;
}

.special .benefit_card__icon {
  background: none !important;
}

.special .benefit_card__icon * {
  fill: black !important;
}

.special .benefit_card__icon img {
  background: none !important;
}

.special.special-color-2 .benefit_card__icon * {
  fill: white !important;
}

.special .promo_benefit_card__icon * {
  fill: black !important;
}

.special.special-color-2 .promo_benefit_card__icon * {
  fill: white !important;
}

.special .swiper-button-prev,
.special .swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 19L17 11.9997L9.00069 5' stroke='%230C0C3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: auto !important;
  background-color: white !important;
}

.special .payment_card__counter {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 50% !important;
}

.special .payment_card__counter--less {
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.31372 24.3135H40.3137' stroke='black' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") !important;
}

.special .payment_card__counter--more {
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.31372 24.3135H40.3137' stroke='black' stroke-width='3' stroke-linecap='round'/%3E%3Cpath d='M24.3137 8.31348V40.3135' stroke='black' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") !important;
}

.special picture:not(img[src]) {
  visibility: hidden !important;
}

.special picture:has(img[src]) {
  visibility: visible !important;
}

.special select {
  background-color: transparent !important;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6L8.00022 11L13 6.00043' stroke='%230C0C3D' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
  background-repeat: no-repeat !important;
  background-size: 24px !important;
  background-position-y: center !important;
  background-position-x: calc(100% - 16px) !important;
}

.d_flex {
  display: flex;
}

.flex_equal > * {
  flex: 1;
}

.no_shrink {
  flex-shrink: 0;
}

.flex_row {
  flex-direction: row;
}

.flex_row_reverse {
  flex-direction: row-reverse;
}

.flex_column {
  flex-direction: column;
}

.flex_column_reverse {
  flex-direction: column-reverse;
}

.justify_content_start {
  justify-content: flex-start;
}

.justify_content_end {
  justify-content: flex-end;
}

.justify_content_center {
  justify-content: center;
}

.justify_content_between {
  justify-content: space-between;
}

.justify_content_around {
  justify-content: space-around;
}

.align_items_start {
  align-items: flex-start;
}

.align_items_center {
  align-items: center;
}

.align_items_end {
  align-items: flex-end;
}

.align_items_stretch {
  align-items: stretch;
}

.flex_wrap {
  flex-wrap: wrap;
}

@media screen and (max-width: 1440px) {
  .flex_row--desktop_large {
    flex-direction: row;
  }
}

@media screen and (max-width: 1440px) {
  .flex_row_reverse--desktop_large {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 1440px) {
  .flex_column--desktop_large {
    flex-direction: column;
  }
}

@media screen and (max-width: 1440px) {
  .flex_column_reverse--desktop_large {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 1440px) {
  .justify_content_start--desktop_large {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1440px) {
  .justify_content_end--desktop_large {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1440px) {
  .justify_content_center--desktop_large {
    justify-content: center;
  }
}

@media screen and (max-width: 1440px) {
  .justify_content_between--desktop_large {
    justify-content: space-between;
  }
}

@media screen and (max-width: 1440px) {
  .justify_content_around--desktop_large {
    justify-content: space-around;
  }
}

@media screen and (max-width: 1440px) {
  .align_items_start--desktop_large {
    align-items: flex-start;
  }
}

@media screen and (max-width: 1440px) {
  .align_items_center--desktop_large {
    align-items: center;
  }
}

@media screen and (max-width: 1440px) {
  .align_items_end--desktop_large {
    align-items: flex-end;
  }
}

@media screen and (max-width: 1440px) {
  .flex_wrap--desktop_large {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1200px) {
  .flex_row--desktop {
    flex-direction: row;
  }
}

@media screen and (max-width: 1200px) {
  .flex_row_reverse--desktop {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 1200px) {
  .flex_column--desktop {
    flex-direction: column;
  }
}

@media screen and (max-width: 1200px) {
  .flex_column_reverse--desktop {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 1200px) {
  .justify_content_start--desktop {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1200px) {
  .justify_content_end--desktop {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1200px) {
  .justify_content_center--desktop {
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  .justify_content_between--desktop {
    justify-content: space-between;
  }
}

@media screen and (max-width: 1200px) {
  .justify_content_around--desktop {
    justify-content: space-around;
  }
}

@media screen and (max-width: 1200px) {
  .align_items_start--desktop {
    align-items: flex-start;
  }
}

@media screen and (max-width: 1200px) {
  .align_items_center--desktop {
    align-items: center;
  }
}

@media screen and (max-width: 1200px) {
  .align_items_end--desktop {
    align-items: flex-end;
  }
}

@media screen and (max-width: 1200px) {
  .flex_wrap--desktop {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1024px) {
  .flex_row--tablet_wide {
    flex-direction: row;
  }
}

@media screen and (max-width: 1024px) {
  .flex_row_reverse--tablet_wide {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 1024px) {
  .flex_column--tablet_wide {
    flex-direction: column;
  }
}

@media screen and (max-width: 1024px) {
  .flex_column_reverse--tablet_wide {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 1024px) {
  .justify_content_start--tablet_wide {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1024px) {
  .justify_content_end--tablet_wide {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1024px) {
  .justify_content_center--tablet_wide {
    justify-content: center;
  }
}

@media screen and (max-width: 1024px) {
  .justify_content_between--tablet_wide {
    justify-content: space-between;
  }
}

@media screen and (max-width: 1024px) {
  .justify_content_around--tablet_wide {
    justify-content: space-around;
  }
}

@media screen and (max-width: 1024px) {
  .align_items_start--tablet_wide {
    align-items: flex-start;
  }
}

@media screen and (max-width: 1024px) {
  .align_items_center--tablet_wide {
    align-items: center;
  }
}

@media screen and (max-width: 1024px) {
  .align_items_end--tablet_wide {
    align-items: flex-end;
  }
}

@media screen and (max-width: 1024px) {
  .flex_wrap--tablet_wide {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .flex_row--tablet {
    flex-direction: row;
  }
}

@media screen and (max-width: 768px) {
  .flex_row_reverse--tablet {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 768px) {
  .flex_column--tablet {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .flex_column_reverse--tablet {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 768px) {
  .justify_content_start--tablet {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .justify_content_end--tablet {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 768px) {
  .justify_content_center--tablet {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .justify_content_between--tablet {
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .justify_content_around--tablet {
    justify-content: space-around;
  }
}

@media screen and (max-width: 768px) {
  .align_items_start--tablet {
    align-items: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .align_items_center--tablet {
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .align_items_end--tablet {
    align-items: flex-end;
  }
}

@media screen and (max-width: 768px) {
  .flex_wrap--tablet {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 500px) {
  .flex_row--phone {
    flex-direction: row;
  }
}

@media screen and (max-width: 500px) {
  .flex_row_reverse--phone {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 500px) {
  .flex_column--phone {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .flex_column_reverse--phone {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 500px) {
  .justify_content_start--phone {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 500px) {
  .justify_content_end--phone {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 500px) {
  .justify_content_center--phone {
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .justify_content_between--phone {
    justify-content: space-between;
  }
}

@media screen and (max-width: 500px) {
  .justify_content_around--phone {
    justify-content: space-around;
  }
}

@media screen and (max-width: 500px) {
  .align_items_start--phone {
    align-items: flex-start;
  }
}

@media screen and (max-width: 500px) {
  .align_items_center--phone {
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .align_items_end--phone {
    align-items: flex-end;
  }
}

@media screen and (max-width: 500px) {
  .flex_wrap--phone {
    flex-wrap: wrap;
  }
}

.d_grid {
  display: grid;
  grid-gap: 24px;
}
@media screen and (max-width: 1024px) {
  .d_grid {
    grid-gap: 16px;
  }
}

.grid_columns_1 {
  grid-template-columns: repeat(1, 1fr);
}

.grid_columns_2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid_columns_3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid_columns_4 {
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 1440px) {
  .grid_columns_1--desktop_large {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 1440px) {
  .grid_columns_2--desktop_large {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1440px) {
  .grid_columns_3--desktop_large {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1440px) {
  .grid_columns_4--desktop_large {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .grid_columns_1--desktop {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .grid_columns_2--desktop {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .grid_columns_3--desktop {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .grid_columns_4--desktop {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .grid_columns_1--tablet_wide {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .grid_columns_2--tablet_wide {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .grid_columns_3--tablet_wide {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .grid_columns_4--tablet_wide {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .grid_columns_1--tablet {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .grid_columns_2--tablet {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .grid_columns_3--tablet {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .grid_columns_4--tablet {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .grid_columns_1--phone {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .grid_columns_2--phone {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .grid_columns_3--phone {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .grid_columns_4--phone {
    grid-template-columns: repeat(4, 1fr);
  }
}

.gap_row_0 > * + * {
  margin-left: 0px;
}

.gap_column_0 > * + * {
  margin-top: 0px;
}

.gap_row_2 > * + * {
  margin-left: 2px;
}

.gap_column_2 > * + * {
  margin-top: 2px;
}

.gap_row_4 > * + * {
  margin-left: 4px;
}

.gap_column_4 > * + * {
  margin-top: 4px;
}

.gap_row_6 > * + * {
  margin-left: 6px;
}

.gap_column_6 > * + * {
  margin-top: 6px;
}

.gap_row_8 > * + * {
  margin-left: 8px;
}

.gap_column_8 > * + * {
  margin-top: 8px;
}

.gap_row_10 > * + * {
  margin-left: 10px;
}

.gap_column_10 > * + * {
  margin-top: 10px;
}

.gap_row_12 > * + * {
  margin-left: 12px;
}

.gap_column_12 > * + * {
  margin-top: 12px;
}

.gap_row_14 > * + * {
  margin-left: 14px;
}

.gap_column_14 > * + * {
  margin-top: 14px;
}

.gap_row_16 > * + * {
  margin-left: 16px;
}

.gap_column_16 > * + * {
  margin-top: 16px;
}

.gap_row_18 > * + * {
  margin-left: 18px;
}

.gap_column_18 > * + * {
  margin-top: 18px;
}

.gap_row_20 > * + * {
  margin-left: 20px;
}

.gap_column_20 > * + * {
  margin-top: 20px;
}

.gap_row_22 > * + * {
  margin-left: 22px;
}

.gap_column_22 > * + * {
  margin-top: 22px;
}

.gap_row_24 > * + * {
  margin-left: 24px;
}

.gap_column_24 > * + * {
  margin-top: 24px;
}

.gap_row_26 > * + * {
  margin-left: 26px;
}

.gap_column_26 > * + * {
  margin-top: 26px;
}

.gap_row_28 > * + * {
  margin-left: 28px;
}

.gap_column_28 > * + * {
  margin-top: 28px;
}

.gap_row_30 > * + * {
  margin-left: 30px;
}

.gap_column_30 > * + * {
  margin-top: 30px;
}

.gap_row_32 > * + * {
  margin-left: 32px;
}

.gap_column_32 > * + * {
  margin-top: 32px;
}

.gap_row_34 > * + * {
  margin-left: 34px;
}

.gap_column_34 > * + * {
  margin-top: 34px;
}

.gap_row_36 > * + * {
  margin-left: 36px;
}

.gap_column_36 > * + * {
  margin-top: 36px;
}

.gap_row_38 > * + * {
  margin-left: 38px;
}

.gap_column_38 > * + * {
  margin-top: 38px;
}

.gap_row_40 > * + * {
  margin-left: 40px;
}

.gap_column_40 > * + * {
  margin-top: 40px;
}

.gap_row_42 > * + * {
  margin-left: 42px;
}

.gap_column_42 > * + * {
  margin-top: 42px;
}

.gap_row_44 > * + * {
  margin-left: 44px;
}

.gap_column_44 > * + * {
  margin-top: 44px;
}

.gap_row_46 > * + * {
  margin-left: 46px;
}

.gap_column_46 > * + * {
  margin-top: 46px;
}

.gap_row_48 > * + * {
  margin-left: 48px;
}

.gap_column_48 > * + * {
  margin-top: 48px;
}

.gap_row_50 > * + * {
  margin-left: 50px;
}

.gap_column_50 > * + * {
  margin-top: 50px;
}

.gap_row_52 > * + * {
  margin-left: 52px;
}

.gap_column_52 > * + * {
  margin-top: 52px;
}

.gap_row_54 > * + * {
  margin-left: 54px;
}

.gap_column_54 > * + * {
  margin-top: 54px;
}

.gap_row_56 > * + * {
  margin-left: 56px;
}

.gap_column_56 > * + * {
  margin-top: 56px;
}

.gap_row_58 > * + * {
  margin-left: 58px;
}

.gap_column_58 > * + * {
  margin-top: 58px;
}

.gap_row_60 > * + * {
  margin-left: 60px;
}

.gap_column_60 > * + * {
  margin-top: 60px;
}

.gap_row_62 > * + * {
  margin-left: 62px;
}

.gap_column_62 > * + * {
  margin-top: 62px;
}

.gap_row_64 > * + * {
  margin-left: 64px;
}

.gap_column_64 > * + * {
  margin-top: 64px;
}

@media screen and (max-width: 1440px) {
  .gap_row_0--desktop_large > * + * {
    margin-left: 0px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_0--desktop_large > * + * {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_2--desktop_large > * + * {
    margin-left: 2px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_2--desktop_large > * + * {
    margin-top: 2px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_4--desktop_large > * + * {
    margin-left: 4px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_4--desktop_large > * + * {
    margin-top: 4px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_6--desktop_large > * + * {
    margin-left: 6px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_6--desktop_large > * + * {
    margin-top: 6px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_8--desktop_large > * + * {
    margin-left: 8px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_8--desktop_large > * + * {
    margin-top: 8px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_10--desktop_large > * + * {
    margin-left: 10px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_10--desktop_large > * + * {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_12--desktop_large > * + * {
    margin-left: 12px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_12--desktop_large > * + * {
    margin-top: 12px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_14--desktop_large > * + * {
    margin-left: 14px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_14--desktop_large > * + * {
    margin-top: 14px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_16--desktop_large > * + * {
    margin-left: 16px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_16--desktop_large > * + * {
    margin-top: 16px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_18--desktop_large > * + * {
    margin-left: 18px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_18--desktop_large > * + * {
    margin-top: 18px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_20--desktop_large > * + * {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_20--desktop_large > * + * {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_22--desktop_large > * + * {
    margin-left: 22px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_22--desktop_large > * + * {
    margin-top: 22px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_24--desktop_large > * + * {
    margin-left: 24px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_24--desktop_large > * + * {
    margin-top: 24px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_26--desktop_large > * + * {
    margin-left: 26px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_26--desktop_large > * + * {
    margin-top: 26px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_28--desktop_large > * + * {
    margin-left: 28px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_28--desktop_large > * + * {
    margin-top: 28px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_30--desktop_large > * + * {
    margin-left: 30px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_30--desktop_large > * + * {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_32--desktop_large > * + * {
    margin-left: 32px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_32--desktop_large > * + * {
    margin-top: 32px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_34--desktop_large > * + * {
    margin-left: 34px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_34--desktop_large > * + * {
    margin-top: 34px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_36--desktop_large > * + * {
    margin-left: 36px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_36--desktop_large > * + * {
    margin-top: 36px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_38--desktop_large > * + * {
    margin-left: 38px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_38--desktop_large > * + * {
    margin-top: 38px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_40--desktop_large > * + * {
    margin-left: 40px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_40--desktop_large > * + * {
    margin-top: 40px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_42--desktop_large > * + * {
    margin-left: 42px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_42--desktop_large > * + * {
    margin-top: 42px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_44--desktop_large > * + * {
    margin-left: 44px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_44--desktop_large > * + * {
    margin-top: 44px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_46--desktop_large > * + * {
    margin-left: 46px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_46--desktop_large > * + * {
    margin-top: 46px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_48--desktop_large > * + * {
    margin-left: 48px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_48--desktop_large > * + * {
    margin-top: 48px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_50--desktop_large > * + * {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_50--desktop_large > * + * {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_52--desktop_large > * + * {
    margin-left: 52px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_52--desktop_large > * + * {
    margin-top: 52px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_54--desktop_large > * + * {
    margin-left: 54px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_54--desktop_large > * + * {
    margin-top: 54px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_56--desktop_large > * + * {
    margin-left: 56px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_56--desktop_large > * + * {
    margin-top: 56px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_58--desktop_large > * + * {
    margin-left: 58px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_58--desktop_large > * + * {
    margin-top: 58px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_60--desktop_large > * + * {
    margin-left: 60px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_60--desktop_large > * + * {
    margin-top: 60px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_62--desktop_large > * + * {
    margin-left: 62px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_62--desktop_large > * + * {
    margin-top: 62px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_row_64--desktop_large > * + * {
    margin-left: 64px;
  }
}

@media screen and (max-width: 1440px) {
  .gap_column_64--desktop_large > * + * {
    margin-top: 64px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_0--desktop > * + * {
    margin-left: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_0--desktop > * + * {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_2--desktop > * + * {
    margin-left: 2px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_2--desktop > * + * {
    margin-top: 2px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_4--desktop > * + * {
    margin-left: 4px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_4--desktop > * + * {
    margin-top: 4px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_6--desktop > * + * {
    margin-left: 6px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_6--desktop > * + * {
    margin-top: 6px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_8--desktop > * + * {
    margin-left: 8px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_8--desktop > * + * {
    margin-top: 8px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_10--desktop > * + * {
    margin-left: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_10--desktop > * + * {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_12--desktop > * + * {
    margin-left: 12px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_12--desktop > * + * {
    margin-top: 12px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_14--desktop > * + * {
    margin-left: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_14--desktop > * + * {
    margin-top: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_16--desktop > * + * {
    margin-left: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_16--desktop > * + * {
    margin-top: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_18--desktop > * + * {
    margin-left: 18px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_18--desktop > * + * {
    margin-top: 18px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_20--desktop > * + * {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_20--desktop > * + * {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_22--desktop > * + * {
    margin-left: 22px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_22--desktop > * + * {
    margin-top: 22px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_24--desktop > * + * {
    margin-left: 24px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_24--desktop > * + * {
    margin-top: 24px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_26--desktop > * + * {
    margin-left: 26px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_26--desktop > * + * {
    margin-top: 26px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_28--desktop > * + * {
    margin-left: 28px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_28--desktop > * + * {
    margin-top: 28px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_30--desktop > * + * {
    margin-left: 30px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_30--desktop > * + * {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_32--desktop > * + * {
    margin-left: 32px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_32--desktop > * + * {
    margin-top: 32px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_34--desktop > * + * {
    margin-left: 34px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_34--desktop > * + * {
    margin-top: 34px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_36--desktop > * + * {
    margin-left: 36px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_36--desktop > * + * {
    margin-top: 36px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_38--desktop > * + * {
    margin-left: 38px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_38--desktop > * + * {
    margin-top: 38px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_40--desktop > * + * {
    margin-left: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_40--desktop > * + * {
    margin-top: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_42--desktop > * + * {
    margin-left: 42px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_42--desktop > * + * {
    margin-top: 42px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_44--desktop > * + * {
    margin-left: 44px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_44--desktop > * + * {
    margin-top: 44px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_46--desktop > * + * {
    margin-left: 46px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_46--desktop > * + * {
    margin-top: 46px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_48--desktop > * + * {
    margin-left: 48px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_48--desktop > * + * {
    margin-top: 48px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_50--desktop > * + * {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_50--desktop > * + * {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_52--desktop > * + * {
    margin-left: 52px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_52--desktop > * + * {
    margin-top: 52px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_54--desktop > * + * {
    margin-left: 54px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_54--desktop > * + * {
    margin-top: 54px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_56--desktop > * + * {
    margin-left: 56px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_56--desktop > * + * {
    margin-top: 56px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_58--desktop > * + * {
    margin-left: 58px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_58--desktop > * + * {
    margin-top: 58px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_60--desktop > * + * {
    margin-left: 60px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_60--desktop > * + * {
    margin-top: 60px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_62--desktop > * + * {
    margin-left: 62px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_62--desktop > * + * {
    margin-top: 62px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_row_64--desktop > * + * {
    margin-left: 64px;
  }
}

@media screen and (max-width: 1200px) {
  .gap_column_64--desktop > * + * {
    margin-top: 64px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_0--tablet_wide > * + * {
    margin-left: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_0--tablet_wide > * + * {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_2--tablet_wide > * + * {
    margin-left: 2px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_2--tablet_wide > * + * {
    margin-top: 2px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_4--tablet_wide > * + * {
    margin-left: 4px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_4--tablet_wide > * + * {
    margin-top: 4px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_6--tablet_wide > * + * {
    margin-left: 6px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_6--tablet_wide > * + * {
    margin-top: 6px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_8--tablet_wide > * + * {
    margin-left: 8px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_8--tablet_wide > * + * {
    margin-top: 8px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_10--tablet_wide > * + * {
    margin-left: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_10--tablet_wide > * + * {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_12--tablet_wide > * + * {
    margin-left: 12px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_12--tablet_wide > * + * {
    margin-top: 12px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_14--tablet_wide > * + * {
    margin-left: 14px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_14--tablet_wide > * + * {
    margin-top: 14px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_16--tablet_wide > * + * {
    margin-left: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_16--tablet_wide > * + * {
    margin-top: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_18--tablet_wide > * + * {
    margin-left: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_18--tablet_wide > * + * {
    margin-top: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_20--tablet_wide > * + * {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_20--tablet_wide > * + * {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_22--tablet_wide > * + * {
    margin-left: 22px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_22--tablet_wide > * + * {
    margin-top: 22px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_24--tablet_wide > * + * {
    margin-left: 24px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_24--tablet_wide > * + * {
    margin-top: 24px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_26--tablet_wide > * + * {
    margin-left: 26px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_26--tablet_wide > * + * {
    margin-top: 26px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_28--tablet_wide > * + * {
    margin-left: 28px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_28--tablet_wide > * + * {
    margin-top: 28px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_30--tablet_wide > * + * {
    margin-left: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_30--tablet_wide > * + * {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_32--tablet_wide > * + * {
    margin-left: 32px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_32--tablet_wide > * + * {
    margin-top: 32px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_34--tablet_wide > * + * {
    margin-left: 34px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_34--tablet_wide > * + * {
    margin-top: 34px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_36--tablet_wide > * + * {
    margin-left: 36px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_36--tablet_wide > * + * {
    margin-top: 36px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_38--tablet_wide > * + * {
    margin-left: 38px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_38--tablet_wide > * + * {
    margin-top: 38px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_40--tablet_wide > * + * {
    margin-left: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_40--tablet_wide > * + * {
    margin-top: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_42--tablet_wide > * + * {
    margin-left: 42px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_42--tablet_wide > * + * {
    margin-top: 42px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_44--tablet_wide > * + * {
    margin-left: 44px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_44--tablet_wide > * + * {
    margin-top: 44px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_46--tablet_wide > * + * {
    margin-left: 46px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_46--tablet_wide > * + * {
    margin-top: 46px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_48--tablet_wide > * + * {
    margin-left: 48px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_48--tablet_wide > * + * {
    margin-top: 48px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_50--tablet_wide > * + * {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_50--tablet_wide > * + * {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_52--tablet_wide > * + * {
    margin-left: 52px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_52--tablet_wide > * + * {
    margin-top: 52px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_54--tablet_wide > * + * {
    margin-left: 54px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_54--tablet_wide > * + * {
    margin-top: 54px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_56--tablet_wide > * + * {
    margin-left: 56px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_56--tablet_wide > * + * {
    margin-top: 56px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_58--tablet_wide > * + * {
    margin-left: 58px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_58--tablet_wide > * + * {
    margin-top: 58px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_60--tablet_wide > * + * {
    margin-left: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_60--tablet_wide > * + * {
    margin-top: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_62--tablet_wide > * + * {
    margin-left: 62px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_62--tablet_wide > * + * {
    margin-top: 62px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_row_64--tablet_wide > * + * {
    margin-left: 64px;
  }
}

@media screen and (max-width: 1024px) {
  .gap_column_64--tablet_wide > * + * {
    margin-top: 64px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_0--tablet > * + * {
    margin-left: 0px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_0--tablet > * + * {
    margin-top: 0px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_2--tablet > * + * {
    margin-left: 2px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_2--tablet > * + * {
    margin-top: 2px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_4--tablet > * + * {
    margin-left: 4px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_4--tablet > * + * {
    margin-top: 4px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_6--tablet > * + * {
    margin-left: 6px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_6--tablet > * + * {
    margin-top: 6px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_8--tablet > * + * {
    margin-left: 8px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_8--tablet > * + * {
    margin-top: 8px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_10--tablet > * + * {
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_10--tablet > * + * {
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_12--tablet > * + * {
    margin-left: 12px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_12--tablet > * + * {
    margin-top: 12px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_14--tablet > * + * {
    margin-left: 14px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_14--tablet > * + * {
    margin-top: 14px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_16--tablet > * + * {
    margin-left: 16px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_16--tablet > * + * {
    margin-top: 16px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_18--tablet > * + * {
    margin-left: 18px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_18--tablet > * + * {
    margin-top: 18px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_20--tablet > * + * {
    margin-left: 20px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_20--tablet > * + * {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_22--tablet > * + * {
    margin-left: 22px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_22--tablet > * + * {
    margin-top: 22px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_24--tablet > * + * {
    margin-left: 24px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_24--tablet > * + * {
    margin-top: 24px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_26--tablet > * + * {
    margin-left: 26px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_26--tablet > * + * {
    margin-top: 26px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_28--tablet > * + * {
    margin-left: 28px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_28--tablet > * + * {
    margin-top: 28px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_30--tablet > * + * {
    margin-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_30--tablet > * + * {
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_32--tablet > * + * {
    margin-left: 32px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_32--tablet > * + * {
    margin-top: 32px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_34--tablet > * + * {
    margin-left: 34px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_34--tablet > * + * {
    margin-top: 34px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_36--tablet > * + * {
    margin-left: 36px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_36--tablet > * + * {
    margin-top: 36px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_38--tablet > * + * {
    margin-left: 38px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_38--tablet > * + * {
    margin-top: 38px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_40--tablet > * + * {
    margin-left: 40px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_40--tablet > * + * {
    margin-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_42--tablet > * + * {
    margin-left: 42px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_42--tablet > * + * {
    margin-top: 42px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_44--tablet > * + * {
    margin-left: 44px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_44--tablet > * + * {
    margin-top: 44px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_46--tablet > * + * {
    margin-left: 46px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_46--tablet > * + * {
    margin-top: 46px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_48--tablet > * + * {
    margin-left: 48px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_48--tablet > * + * {
    margin-top: 48px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_50--tablet > * + * {
    margin-left: 50px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_50--tablet > * + * {
    margin-top: 50px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_52--tablet > * + * {
    margin-left: 52px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_52--tablet > * + * {
    margin-top: 52px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_54--tablet > * + * {
    margin-left: 54px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_54--tablet > * + * {
    margin-top: 54px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_56--tablet > * + * {
    margin-left: 56px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_56--tablet > * + * {
    margin-top: 56px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_58--tablet > * + * {
    margin-left: 58px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_58--tablet > * + * {
    margin-top: 58px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_60--tablet > * + * {
    margin-left: 60px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_60--tablet > * + * {
    margin-top: 60px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_62--tablet > * + * {
    margin-left: 62px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_62--tablet > * + * {
    margin-top: 62px;
  }
}

@media screen and (max-width: 768px) {
  .gap_row_64--tablet > * + * {
    margin-left: 64px;
  }
}

@media screen and (max-width: 768px) {
  .gap_column_64--tablet > * + * {
    margin-top: 64px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_0--phone > * + * {
    margin-left: 0px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_0--phone > * + * {
    margin-top: 0px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_2--phone > * + * {
    margin-left: 2px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_2--phone > * + * {
    margin-top: 2px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_4--phone > * + * {
    margin-left: 4px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_4--phone > * + * {
    margin-top: 4px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_6--phone > * + * {
    margin-left: 6px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_6--phone > * + * {
    margin-top: 6px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_8--phone > * + * {
    margin-left: 8px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_8--phone > * + * {
    margin-top: 8px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_10--phone > * + * {
    margin-left: 10px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_10--phone > * + * {
    margin-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_12--phone > * + * {
    margin-left: 12px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_12--phone > * + * {
    margin-top: 12px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_14--phone > * + * {
    margin-left: 14px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_14--phone > * + * {
    margin-top: 14px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_16--phone > * + * {
    margin-left: 16px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_16--phone > * + * {
    margin-top: 16px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_18--phone > * + * {
    margin-left: 18px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_18--phone > * + * {
    margin-top: 18px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_20--phone > * + * {
    margin-left: 20px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_20--phone > * + * {
    margin-top: 20px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_22--phone > * + * {
    margin-left: 22px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_22--phone > * + * {
    margin-top: 22px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_24--phone > * + * {
    margin-left: 24px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_24--phone > * + * {
    margin-top: 24px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_26--phone > * + * {
    margin-left: 26px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_26--phone > * + * {
    margin-top: 26px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_28--phone > * + * {
    margin-left: 28px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_28--phone > * + * {
    margin-top: 28px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_30--phone > * + * {
    margin-left: 30px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_30--phone > * + * {
    margin-top: 30px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_32--phone > * + * {
    margin-left: 32px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_32--phone > * + * {
    margin-top: 32px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_34--phone > * + * {
    margin-left: 34px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_34--phone > * + * {
    margin-top: 34px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_36--phone > * + * {
    margin-left: 36px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_36--phone > * + * {
    margin-top: 36px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_38--phone > * + * {
    margin-left: 38px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_38--phone > * + * {
    margin-top: 38px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_40--phone > * + * {
    margin-left: 40px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_40--phone > * + * {
    margin-top: 40px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_42--phone > * + * {
    margin-left: 42px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_42--phone > * + * {
    margin-top: 42px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_44--phone > * + * {
    margin-left: 44px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_44--phone > * + * {
    margin-top: 44px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_46--phone > * + * {
    margin-left: 46px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_46--phone > * + * {
    margin-top: 46px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_48--phone > * + * {
    margin-left: 48px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_48--phone > * + * {
    margin-top: 48px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_50--phone > * + * {
    margin-left: 50px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_50--phone > * + * {
    margin-top: 50px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_52--phone > * + * {
    margin-left: 52px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_52--phone > * + * {
    margin-top: 52px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_54--phone > * + * {
    margin-left: 54px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_54--phone > * + * {
    margin-top: 54px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_56--phone > * + * {
    margin-left: 56px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_56--phone > * + * {
    margin-top: 56px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_58--phone > * + * {
    margin-left: 58px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_58--phone > * + * {
    margin-top: 58px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_60--phone > * + * {
    margin-left: 60px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_60--phone > * + * {
    margin-top: 60px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_62--phone > * + * {
    margin-left: 62px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_62--phone > * + * {
    margin-top: 62px;
  }
}

@media screen and (max-width: 500px) {
  .gap_row_64--phone > * + * {
    margin-left: 64px;
  }
}

@media screen and (max-width: 500px) {
  .gap_column_64--phone > * + * {
    margin-top: 64px;
  }
}

*[data-mobile-appear-type=block] {
  display: none !important;
}
@media screen and (max-width: 500px) {
  *[data-mobile-appear-type=block] {
    display: block !important;
  }
}

*[data-mobile-appear-type=flex] {
  display: none !important;
}
@media screen and (max-width: 500px) {
  *[data-mobile-appear-type=flex] {
    display: flex !important;
  }
}

*[data-tablet-appear-type=block] {
  display: none !important;
}
@media screen and (max-width: 768px) {
  *[data-tablet-appear-type=block] {
    display: block !important;
  }
}

*[data-tablet-appear-type=flex] {
  display: none !important;
}
@media screen and (max-width: 768px) {
  *[data-tablet-appear-type=flex] {
    display: flex !important;
  }
}

*[data-tablet-wide-appear-type=block] {
  display: none !important;
}
@media screen and (max-width: 1024px) {
  *[data-tablet-wide-appear-type=block] {
    display: block !important;
  }
}

*[data-tablet-wide-appear-type=flex] {
  display: none !important;
}
@media screen and (max-width: 1024px) {
  *[data-tablet-wide-appear-type=flex] {
    display: flex !important;
  }
}

*[data-desktop-appear-type=block] {
  display: none !important;
}
@media screen and (max-width: 1200px) {
  *[data-desktop-appear-type=block] {
    display: block !important;
  }
}

*[data-desktop-appear-type=flex] {
  display: none !important;
}
@media screen and (max-width: 1200px) {
  *[data-desktop-appear-type=flex] {
    display: flex !important;
  }
}

@media screen and (max-width: 500px) {
  *[data-mobile-disappear] {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  *[data-tablet-disappear] {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  *[data-tablet-wide-disappear] {
    display: none !important;
  }
}

@media screen and (max-width: 1200px) {
  *[data-desktop-disappear] {
    display: none !important;
  }
}

.header {
  position: sticky;
  top: 0;
  background-color: white;
  padding: 20px 0;
  z-index: 10;
}
@media screen and (max-width: 1024px) {
  .header {
    padding: 0;
  }
}
.header .wrapper {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1024px) {
  .header .wrapper {
    padding: 0;
  }
}
.header .wrapper > * + * {
  margin-top: 20px;
}
@media screen and (max-width: 1024px) {
  .header .wrapper > * + * {
    margin-top: 0;
  }
}
.header_top {
  display: flex;
  align-items: center;
  z-index: 10;
}
@media screen and (max-width: 1024px) {
  .header_top {
    box-shadow: 0px 2px 16px rgba(0, 84, 121, 0.02), 0px 0.700079px 9px rgba(0, 84, 121, 0.02), 0px 0.339395px 8px rgba(0, 84, 121, 0.02);
    background-color: white;
    padding-right: 20px;
  }
}
.header_top > * {
  flex-shrink: 0;
}
.header_top > * + * {
  margin-left: 12px;
}
@media screen and (max-width: 500px) {
  .header_top .gs_button {
    margin-left: auto;
  }
}
.header_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 9;
}
@media screen and (max-width: 1024px) {
  .header_bottom {
    flex-direction: column;
    box-shadow: 0px 3px 64px rgba(0, 5, 22, 0.3);
    position: absolute;
    width: 100%;
    background-color: white;
    top: 64px;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    padding: 16px;
    transition: 0.25s ease;
    max-height: calc(100vh - 64px);
    overflow-y: auto;
  }
}
.header_bottom > * + * {
  margin-left: 8px;
}
@media screen and (max-width: 1024px) {
  .header_bottom > * + * {
    margin-left: 0;
    margin-top: 16px;
  }
}
@media screen and (max-width: 1024px) {
  .header_bottom.opened {
    pointer-events: initial;
    visibility: visible;
    opacity: 1;
  }
}
.header .gs_link--active {
  color: #04665E;
}
@media screen and (max-width: 1024px) {
  .header nav {
    width: 100%;
  }
}
.header nav > ul {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 1024px) {
  .header nav > ul {
    flex-direction: column;
  }
}
.header nav > ul li {
  margin-right: 16px;
  margin-bottom: 10px;
}
@media screen and (max-width: 1024px) {
  .header nav > ul li {
    margin-right: 0;
  }
}
.header nav > ul a {
  display: block;
}
.header .contacts {
  display: flex;
  flex-shrink: 0;
}
@media screen and (max-width: 1024px) {
  .header .contacts {
    flex-direction: column;
  }
}
.header .contacts > * + * {
  margin-left: 32px;
}
@media screen and (max-width: 1024px) {
  .header .contacts > * + * {
    margin-left: 0;
    margin-top: 12px;
  }
}
.header .buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.header .buttons > * + * {
  margin-top: 8px;
}
.header .logo {
  margin-left: 2px;
}
@media screen and (max-width: 768px) {
  .header .logo svg {
    width: 100px;
  }
}

.footer {
  background-color: #F8FBFE;
  padding: 40px 0;
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 20px 0;
  }
}
.footer_container {
  display: flex;
}
@media screen and (max-width: 768px) {
  .footer_container {
    flex-direction: column;
  }
}
.footer_container > * + * {
  margin-left: 64px;
}
@media screen and (max-width: 1024px) {
  .footer_container > * + * {
    margin-left: 32px;
  }
}
@media screen and (max-width: 768px) {
  .footer_container > * + * {
    margin-left: 0;
    margin-top: 32px;
  }
}
.footer_block > * + * {
  margin-left: 24px;
}
@media screen and (max-width: 1024px) {
  .footer_block > * + * {
    margin-left: 16px;
  }
}
@media screen and (max-width: 768px) {
  .footer_block > * + * {
    margin-left: 0;
    margin-top: 32px;
  }
}
.footer_block_content {
  display: flex;
  flex-direction: column;
}
.footer_block_content > * + * {
  margin-top: 24px;
}
@media screen and (max-width: 768px) {
  .footer_block_content > * + * {
    margin-top: 16px;
  }
}

.admin_text_block .collapse {
  display: none;
}
.admin_text_block hr {
  border-color: #27ACA1;
  margin: 16px 0;
}
@media screen and (max-width: 768px) {
  .admin_text_block hr {
    margin: 8px 0;
  }
}
.admin_text_block h1 {
  font-weight: 800;
}
.admin_text_block h1 {
  font-size: 24px;
  line-height: 28px;
}
@media screen and (min-width: 500px) {
  .admin_text_block h1 {
    font-size: calc(24px + 12 * ((100vw - 500px) / 940));
    line-height: calc(28px + 18 * ((100vw - 500px) / 940));
  }
}
@media screen and (min-width: 1440px) {
  .admin_text_block h1 {
    font-size: 36px;
    line-height: 46px;
  }
}
.admin_text_block h2 {
  font-weight: 500;
}
.admin_text_block h2 {
  font-size: 22px;
  line-height: 28px;
}
@media screen and (min-width: 500px) {
  .admin_text_block h2 {
    font-size: calc(22px + 4 * ((100vw - 500px) / 940));
    line-height: calc(28px + 8 * ((100vw - 500px) / 940));
  }
}
@media screen and (min-width: 1440px) {
  .admin_text_block h2 {
    font-size: 26px;
    line-height: 36px;
  }
}
.admin_text_block h3 {
  font-weight: 500;
}
.admin_text_block h3 {
  font-size: 15px;
  line-height: 20px;
}
@media screen and (min-width: 500px) {
  .admin_text_block h3 {
    font-size: calc(15px + 7 * ((100vw - 500px) / 940));
    line-height: calc(20px + 10 * ((100vw - 500px) / 940));
  }
}
@media screen and (min-width: 1440px) {
  .admin_text_block h3 {
    font-size: 22px;
    line-height: 30px;
  }
}
.admin_text_block h1, .admin_text_block h2, .admin_text_block h3 {
  margin-bottom: 16px;
}
.admin_text_block ul {
  list-style-type: disc;
  margin-left: 24px;
}
.admin_text_block ol {
  list-style-type: decimal;
  margin-left: 24px;
}
.admin_text_block li + li {
  margin-top: 8px;
}
.admin_text_block img {
  width: 100%;
  height: auto;
  border-radius: 16px;
}
.admin_text_block * + .gs_button {
  margin-top: 16px;
}
.gs_top_button {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 16L11.9997 8L5 15.9993' stroke='%230C0C3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: auto !important;
  background-color: white !important;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 56px;
  height: 56px;
  box-shadow: 0px 28px 53px rgba(0, 25, 80, 0.08), 0px 10.2205px 15.1703px rgba(0, 25, 80, 0.0551985), 0px 4.96184px 8.87643px rgba(0, 25, 80, 0.0445033), 0px 2.43239px 6.24775px rgba(0, 25, 80, 0.0354967), 0px 0.96177px 3.59913px rgba(0, 25, 80, 0.0248015);
  border: 1px solid rgba(207, 215, 220, 0.25);
  border-radius: 50%;
  transition: 0.25s ease;
  z-index: 90;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}
.gs_top_button:hover, .gs_top_button:focus-visible {
  border-color: #CFD7DC;
}
.gs_top_button.visible {
  opacity: 1;
  visibility: visible;
}

.hamburger {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 64px;
  height: 64px;
}
.hamburger span {
  display: block;
  width: 24px;
  height: 1.5px;
  border-radius: 8px;
  background-color: #0C0C3D;
  transition: transform 0.25s ease;
}
.hamburger span + span {
  margin-top: 6px;
}
.hamburger.opened span {
  width: 32px;
  margin: 0;
}
.hamburger.opened span:first-child {
  position: absolute;
  transform: rotate(45deg);
}
.hamburger.opened span:nth-child(2) {
  transition: none;
  opacity: 0;
}
.hamburger.opened span:last-child {
  position: absolute;
  transform: rotate(-45deg);
}

.h1 {
  font-weight: 800;
}
.h1 {
  font-size: 24px;
  line-height: 28px;
}
@media screen and (min-width: 500px) {
  .h1 {
    font-size: calc(24px + 12 * ((100vw - 500px) / 940));
    line-height: calc(28px + 18 * ((100vw - 500px) / 940));
  }
}
@media screen and (min-width: 1440px) {
  .h1 {
    font-size: 36px;
    line-height: 46px;
  }
}
.h1--margin {
  margin-bottom: 32px;
}
@media screen and (max-width: 768px) {
  .h1--margin {
    margin-bottom: 16px;
  }
}
.h1--center {
  text-align: center;
}

.h2, .benefit_card__icon {
  font-weight: 500;
}
.h2, .benefit_card__icon {
  font-size: 22px;
  line-height: 28px;
}
@media screen and (min-width: 500px) {
  .h2, .benefit_card__icon {
    font-size: calc(22px + 4 * ((100vw - 500px) / 940));
    line-height: calc(28px + 8 * ((100vw - 500px) / 940));
  }
}
@media screen and (min-width: 1440px) {
  .h2, .benefit_card__icon {
    font-size: 26px;
    line-height: 36px;
  }
}
.h2--margin {
  margin-bottom: 32px;
}
@media screen and (max-width: 768px) {
  .h2--margin {
    margin-bottom: 16px;
  }
}
.h2--center {
  text-align: center;
}

.h3, .promo_benefit_card__title, .faq_card__title {
  font-weight: 500;
}
.h3, .promo_benefit_card__title, .faq_card__title {
  font-size: 15px;
  line-height: 20px;
}
@media screen and (min-width: 500px) {
  .h3, .promo_benefit_card__title, .faq_card__title {
    font-size: calc(15px + 7 * ((100vw - 500px) / 940));
    line-height: calc(20px + 10 * ((100vw - 500px) / 940));
  }
}
@media screen and (min-width: 1440px) {
  .h3, .promo_benefit_card__title, .faq_card__title {
    font-size: 22px;
    line-height: 30px;
  }
}
.h3--margin {
  margin-bottom: 32px;
}
@media screen and (max-width: 768px) {
  .h3--margin {
    margin-bottom: 16px;
  }
}
.h3--center {
  text-align: center;
}

.header_with_link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
@media screen and (max-width: 768px) {
  .header_with_link {
    margin-bottom: 16px;
  }
}

.text--primary {
  color: #27ACA1;
}

.text--secondary {
  color: #DCF7F4;
}

.text--pale {
  color: #5D647D;
}

.text--small_size {
  font-size: 12px;
  line-height: 16px;
}

.text--medium_size {
  font-size: 14px;
  line-height: 20px;
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.text--normal {
  font-weight: 400;
}

.text--medium {
  font-weight: 500;
}

.text--semibold {
  font-weight: 600;
}

.text--bold {
  font-weight: 700;
}

.text--underlined {
  text-decoration: underline;
}

.gs_button {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 16px 32px;
  transition: 0.25s ease;
  font-weight: 600;
  width: fit-content;
  min-height: 46px;
}
@media screen and (max-width: 768px) {
  .gs_button {
    padding: 12px 24px;
  }
}

.gs_button--primary {
  color: white;
  background-color: #27ACA1;
}
.gs_button--primary:hover, .gs_button--primary:focus-visible {
  background-color: #32BCB1;
}
.gs_button--primary:active {
  background-color: #04665E;
}

.gs_button--secondary {
  color: #27ACA1;
  background-color: #DCF7F4;
}
.gs_button--secondary:hover, .gs_button--secondary:focus-visible {
  color: #04665E;
}
.gs_button--secondary:active {
  background-color: #F6F8FA;
}

.gs_button--white {
  color: #27ACA1;
  background-color: white;
}
.gs_button--white:hover, .gs_button--white:focus-visible {
  color: #04665E;
}
.gs_button--white:active {
  color: #0C0C3D;
}

.gs_button--disabled {
  user-select: none;
  pointer-events: none;
  color: #5D647D !important;
}

.gs_button--small {
  padding: 9px 24px;
}

.gs_button--large {
  padding: 24px 40px;
  font-weight: 500;
}
.gs_button--large {
  font-size: 14px;
  line-height: 22px;
}
@media screen and (min-width: 500px) {
  .gs_button--large {
    font-size: calc(14px + 22 * ((100vw - 500px) / 940));
    line-height: calc(22px + 24 * ((100vw - 500px) / 940));
  }
}
@media screen and (min-width: 1440px) {
  .gs_button--large {
    font-size: 36px;
    line-height: 46px;
  }
}
@media screen and (max-width: 768px) {
  .gs_button--large {
    padding: 12px 24px;
  }
}

.gs_button--full {
  width: 100%;
}

.gs_button--margin {
  margin-top: 32px;
}
@media screen and (max-width: 768px) {
  .gs_button--margin {
    margin-top: 16px;
  }
}

.gs_button--with_icon {
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.gs_button--with_icon svg {
  flex-shrink: 0;
  margin-right: 12px;
}

.jquery-modal {
  z-index: 99;
  background-color: rgba(6, 6, 37, 0.5);
}

.bg--login {
  background-image: url("/static/build/img/login_bg.jpg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  background-color: initial !important;
}
@media screen and (max-width: 768px) {
  .bg--login {
    background-image: url("/static/build/img/login_bg--tablet.jpg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    background-color: initial !important;
  }
}

.bg--white {
  background-color: white;
}

.bg--pale {
  background-color: #F6F8FA;
}

.bg--wide .gs_modal {
  width: 784px;
}

.gs_modal {
  width: 515px;
  max-width: 100%;
  padding: 0;
  outline: none;
  box-shadow: none;
  background-color: transparent;
}
.gs_modal__close {
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 13L35.6274 35.6274' stroke='%235D647D' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M35.627 13L12.9995 35.6274' stroke='%235D647D' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: auto !important;
  background-color: initial !important;
  width: 38px;
  height: 38px;
}
.gs_modal__close--alt {
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 13L35.6274 35.6274' stroke='%2327ACA1' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M35.6289 13L13.0015 35.6274' stroke='%2327ACA1' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: auto !important;
  background-color: initial !important;
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 8px;
}
@media screen and (max-width: 768px) {
  .gs_modal__close--alt {
    width: 48px;
    height: 48px;
  }
}
.gs_modal__top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
}
@media screen and (max-width: 768px) {
  .gs_modal__top {
    margin-bottom: 20px;
  }
}
.gs_modal__top img {
  display: block;
}
.gs_modal__inner {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: none;
  border-radius: 16px;
  padding: 24px;
  background-color: white;
}
@media screen and (max-width: 768px) {
  .gs_modal__inner {
    padding: 16px;
  }
}
.gs_modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gs_modal__body {
  display: flex;
  flex-direction: column;
}
.gs_modal__inner > * + *, .gs_modal__inner .gs_form > * + *, .gs_modal__body > * + * {
  margin-top: 32px;
}
@media screen and (max-width: 768px) {
  .gs_modal__inner > * + *, .gs_modal__inner .gs_form > * + *, .gs_modal__body > * + * {
    margin-top: 16px;
  }
}
.gs_modal .gs_form > [type=hidden] + * {
  margin-top: 0;
}
.gs_modal__footer {
  display: flex;
  flex-direction: column;
}
.gs_modal__footer > * + * {
  margin-top: 12px;
}

.gs_link, .faq_card__title, .call_to_action, .supporters__link {
  outline: none;
  border: none;
  background-color: transparent;
  transition: 0.25s ease;
  width: fit-content;
}
.gs_link:hover, .faq_card__title:hover, .call_to_action:hover, .supporters__link:hover, .gs_link:focus-visible, .faq_card__title:focus-visible, .call_to_action:focus-visible, .supporters__link:focus-visible {
  color: #32BCB1;
}

.gs_link--secondary {
  color: #DCF7F4;
}
.gs_link--secondary:hover, .gs_link--secondary:focus-visible {
  color: white;
}

.gs_link--semibold {
  font-weight: 600;
}

.gs_link--underlined {
  text-decoration: underline;
}

.gs_link--colored {
  color: #27ACA1;
}

.gs_link--with_icon {
  display: flex;
  align-items: center;
}
.gs_link--with_icon svg {
  flex-shrink: 0;
  margin-right: 8px;
}

.gs_link--with_arrow_down {
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 6L8.50022 11L13.5 6.00043' stroke='%2327ACA1' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: right center !important;
  background-size: auto !important;
  background-color: initial !important;
  padding-right: 20px;
}

.gs_link--with_arrow_right {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.03072 12.473L5.71396 11.169L9.65785 7.22514H0.0761719V5.32031H9.65785L5.71396 1.38281L7.03072 0.0724429L13.231 6.27273L7.03072 12.473Z' fill='%2327ACA1'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: right center !important;
  background-size: auto !important;
  background-color: initial !important;
  padding-right: 20px;
}

.gs_link--stretched {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.gs_list {
  position: relative;
}

.gs_list--dots li + li {
  margin-top: 8px;
}
.gs_list--dots li {
  position: relative;
  padding-left: 18px;
}
.gs_list--dots li::before {
  content: "";
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  width: 6px;
  height: 6px;
  background-color: #27ACA1;
  border-radius: 50%;
}

.gs_label {
  display: inline-block;
  width: fit-content;
  border: 1px solid #5D647D;
  border-radius: 8px;
  padding: 3px 8px;
}
@media screen and (max-width: 768px) {
  .gs_label {
    border-radius: 4px;
    padding: 2px 5px;
  }
}

.gs_label--primary {
  background-color: #27ACA1;
  border-color: #27ACA1;
  color: white;
}

.gs_label--secondary {
  background-color: #DCF7F4;
  border-color: #DCF7F4;
  color: #0C0C3D;
}

.gs_table, .admin_text_block table {
  display: table;
}
@media screen and (max-width: 768px) {
  .gs_table, .admin_text_block table {
    display: inline-table;
    overflow-x: auto;
  }
}
.gs_table tr, .admin_text_block table tr {
  border-top: 2px solid #27ACA1;
}
.gs_table td, .admin_text_block table td {
  vertical-align: top;
  min-width: 200px;
}
.gs_table th, .admin_text_block table th,
.gs_table td,
.admin_text_block table td {
  padding: 16px 8px;
}

.gs_table--short tr:nth-child(n+6) {
  display: none;
}

.gs_tabs {
  display: flex;
}
.gs_tabs--full {
  width: 100%;
}
.gs_tabs--full .gs_tabs__item {
  width: 100%;
}
.gs_tabs__item {
  background-color: transparent;
  border: none;
  outline: none;
  color: #5D647D;
  text-align: center;
  border-bottom: 2px solid transparent;
  transition: 0.25s ease;
  padding-bottom: 4px;
}
@media screen and (max-width: 500px) {
  .gs_tabs__item {
    width: 100%;
  }
}
.gs_tabs__item + .gs_tabs__item {
  margin-left: 32px;
}
@media screen and (max-width: 768px) {
  .gs_tabs__item + .gs_tabs__item {
    margin-left: 16px;
  }
}
.gs_tabs__item:hover, .gs_tabs__item:focus-visible {
  color: #27ACA1;
}
.gs_tabs__item--large {
  font-weight: 500;
}
.gs_tabs__item--large {
  font-size: 14px;
  line-height: 22px;
}
@media screen and (min-width: 500px) {
  .gs_tabs__item--large {
    font-size: calc(14px + 16 * ((100vw - 500px) / 940));
    line-height: calc(22px + 18 * ((100vw - 500px) / 940));
  }
}
@media screen and (min-width: 1440px) {
  .gs_tabs__item--large {
    font-size: 30px;
    line-height: 40px;
  }
}
.gs_tabs__item--active {
  color: #27ACA1;
  border-color: #27ACA1;
  pointer-events: none;
}

.search_panel {
  position: relative;
  flex: 1;
}
.search_panel input[type=search] {
  width: 100%;
  min-width: 50px;
  background-color: transparent;
  border: 1px solid #CFD7DC;
  border-radius: 8px;
  padding: 9px 12px 9px 40px;
  transition: 0.25s ease;
  outline: none;
}
.search_panel input[type=search]:-webkit-search-cancel-button {
  appearance: none !important;
  -webkit-appearance: none !important;
}
.search_panel input[type=search]:-webkit-autofill,
.search_panel input[type=search]:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #DCF7F4 inset;
  -webkit-text-fill-color: #0C0C3D;
}
.search_panel input[type=search]::placeholder {
  color: #5D647D;
}
.search_panel input[type=search]:focus-visible {
  border-color: #32BCB1;
}
.search_panel button[type=submit] {
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13Z' stroke='%235D647D' stroke-width='1.2' stroke-linejoin='round'/%3E%3Cpath d='M11.5 11.5L16 16' stroke='%235D647D' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
  background-repeat: no-repeat !important;
  background-position: 15px center !important;
  background-size: auto !important;
  background-color: initial !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 100%;
  border: none;
  outline: none;
  padding: 0;
  font-size: 0;
}

.socials {
  display: flex;
}
.socials__item + .socials__item {
  margin-left: 8px;
}
.socials__item a {
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  background-color: #DCF7F4;
  border-radius: 8px;
  padding: 6px;
}
.socials__item path {
  transition: 0.25s ease;
}
.socials__item a:hover path, .socials__item a:focus-visible path {
  fill: #32BCB1;
}
.socials__item a:active path {
  fill: #04665E;
}

.supporters {
  display: flex;
  flex-direction: column;
}
.supporters__item + .supporters__item {
  margin-top: 24px;
}
@media screen and (max-width: 768px) {
  .supporters__item + .supporters__item {
    margin-top: 12px;
  }
}
.supporters__link {
  display: block;
  opacity: 0.85;
}
.supporters__link:hover, .supporters__link:focus-visible {
  opacity: 1;
}
.supporters__img {
  width: auto;
}
@media screen and (max-width: 768px) {
  .supporters__img {
    max-height: 70px;
  }
}

.gs_subscription {
  background-color: #F8FBFE;
  border-radius: 16px;
}
.gs_subscription__form {
  padding: 24px;
}
@media screen and (max-width: 768px) {
  .gs_subscription__form {
    padding: 16px;
  }
}
.gs_subscription__image {
  border-left: 1px solid #CFD7DC;
  padding: 0 24px 24px;
}
@media screen and (max-width: 1024px) {
  .gs_subscription__image {
    border-top: 1px solid #CFD7DC;
    border-left: none;
  }
}
@media screen and (max-width: 768px) {
  .gs_subscription__image {
    padding: 0 16px 16px;
  }
}
@media screen and (max-width: 500px) {
  .gs_subscription__image {
    padding: 16px;
  }
}
.gs_subscription__image img {
  width: 100%;
  max-width: 500px;
}

.gs_filter {
  outline: none;
  font-weight: 600;
  color: #27ACA1;
  background-color: #DCF7F4;
  border: 1px solid #27ACA1;
  border-radius: 25px;
  padding: 8px 16px;
  transition: color 0.25s ease, background-color 0.25s ease, padding 0.1s ease;
  margin-right: 8px;
  margin-bottom: 8px;
}
@media screen and (max-width: 768px) {
  .gs_filter {
    padding: 6px 12px;
  }
}
.gs_filter:hover, .gs_filter:focus-visible {
  color: #04665E;
}
.gs_filter--active {
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='28' height='28' rx='14' fill='%2327ACA1'/%3E%3Cpath d='M20.0458 9.4856C20.3299 9.78703 20.3158 10.2617 20.0144 10.5458L12.0846 18.0197C11.7483 18.3366 11.2204 18.3269 10.8961 17.9977L8.21576 15.2774C7.92504 14.9823 7.92856 14.5075 8.22361 14.2167C8.51867 13.926 8.99353 13.9295 9.28424 14.2246L11.5184 16.4921L18.9856 9.45421C19.287 9.17011 19.7617 9.18416 20.0458 9.4856Z' fill='white'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: 9px center !important;
  background-size: 28px 28px !important;
  background-color: #DCF7F4 !important;
  padding-left: 46px;
  pointer-events: none;
}
@media screen and (max-width: 768px) {
  .gs_filter--active {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='10' fill='%2327ACA1'/%3E%3Cpath d='M16.0458 5.4856C16.3299 5.78703 16.3158 6.26169 16.0144 6.54579L8.08456 14.0197C7.74829 14.3366 7.22042 14.3269 6.89609 13.9977L4.21576 11.2774C3.92504 10.9823 3.92856 10.5075 4.22361 10.2167C4.51867 9.92602 4.99353 9.92954 5.28424 10.2246L7.51839 12.4921L14.9856 5.45421C15.287 5.17011 15.7617 5.18416 16.0458 5.4856Z' fill='white'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: 8px center !important;
    background-size: 20px 20px !important;
    background-color: #DCF7F4 !important;
    padding-left: 36px;
  }
}

@media screen and (max-width: 1024px) {
  .limited li:nth-child(n+5) {
    display: none;
  }
}

.gs_card, .active_event_card, .package_card, .promo_benefit_card, .benefit_card, .faq_card, .person_card, .event_speaker_card, .service_card, .event_card, .legal_service_card, .user_document, .gs_activity, .gs_education_step, .call_to_action, .document_wrapper {
  position: relative;
  background-color: #F6F8FA;
  border-radius: 16px;
  padding: 24px;
}
@media screen and (max-width: 768px) {
  .gs_card, .active_event_card, .package_card, .promo_benefit_card, .benefit_card, .faq_card, .person_card, .event_speaker_card, .service_card, .event_card, .legal_service_card, .user_document, .gs_activity, .gs_education_step, .call_to_action, .document_wrapper {
    padding: 16px;
  }
}

.documents_block {
  width: 60%;
}
@media screen and (max-width: 1200px) {
  .documents_block {
    width: 100%;
  }
}
.documents_block .slider-wrapper {
  width: 100%;
  margin-left: 0;
}
@media screen and (max-width: 500px) {
  .documents_block .slider-wrapper {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}
.documents_block .swiper {
  width: calc(100% - 50px);
}

.document_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}
.document_wrapper picture {
  border: 1px solid #CFD7DC;
  border-radius: 8px;
  margin: 0;
}
.document_wrapper img {
  width: 100%;
  border-radius: 8px;
}
@media screen and (max-width: 1200px) {
  .document_wrapper img {
    max-width: 350px;
  }
}
.document_wrapper > * + * {
  margin-top: 8px;
}
.document_wrapper .gs_link--stretched:hover ~ .gs_link, .document_wrapper .gs_link--stretched:hover ~ .faq_card__title, .document_wrapper .gs_link--stretched:hover ~ .call_to_action, .document_wrapper .gs_link--stretched:hover ~ .supporters__link,
.document_wrapper .gs_link--stretched:focus-visible ~ .gs_link,
.document_wrapper .gs_link--stretched:focus-visible ~ .faq_card__title,
.document_wrapper .gs_link--stretched:focus-visible ~ .call_to_action,
.document_wrapper .gs_link--stretched:focus-visible ~ .supporters__link {
  color: #32BCB1;
}
.document_wrapper .gs_link--stretched:active ~ .gs_link, .document_wrapper .gs_link--stretched:active ~ .faq_card__title, .document_wrapper .gs_link--stretched:active ~ .call_to_action, .document_wrapper .gs_link--stretched:active ~ .supporters__link {
  color: #04665E;
}

.gradient_text {
  background-color: #00B6A7;
  background-image: linear-gradient(45deg, #00B6A7, #0081DE);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.gs_ajax_loader {
  color: transparent !important;
  transition: color 0s;
}

.gs_ajax_loader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 96C41.5219 96 35.2313 94.7344 29.3156 92.2312C23.5969 89.8125 18.4688 86.3438 14.0625 81.9375C9.65625 77.5312 6.1875 72.4031 3.76875 66.6844C1.26563 60.7687 0 54.4781 0 48C0 46.1344 1.50938 44.625 3.375 44.625C5.24063 44.625 6.75 46.1344 6.75 48C6.75 53.5687 7.8375 58.9688 9.99375 64.0594C12.075 68.9719 15.0469 73.3875 18.8344 77.175C22.6219 80.9625 27.0375 83.9437 31.95 86.0156C37.0313 88.1625 42.4313 89.25 48 89.25C53.5688 89.25 58.9688 88.1625 64.0594 86.0063C68.9719 83.925 73.3875 80.9531 77.175 77.1656C80.9625 73.3781 83.9438 68.9625 86.0156 64.05C88.1625 58.9688 89.25 53.5687 89.25 48C89.25 42.4313 88.1625 37.0312 86.0063 31.9406C83.9321 27.0399 80.9301 22.5864 77.1656 18.825C73.4084 15.0555 68.9538 12.0528 64.05 9.98438C58.9688 7.8375 53.5688 6.75 48 6.75C46.1344 6.75 44.625 5.24062 44.625 3.375C44.625 1.50938 46.1344 0 48 0C54.4781 0 60.7688 1.26562 66.6844 3.76875C72.4031 6.1875 77.5313 9.65625 81.9375 14.0625C86.3438 18.4688 89.8031 23.6063 92.2219 29.3156C94.725 35.2313 95.9906 41.5219 95.9906 48C95.9906 54.4781 94.725 60.7687 92.2219 66.6844C89.8125 72.4031 86.3438 77.5312 81.9375 81.9375C77.5313 86.3438 72.3938 89.8031 66.6844 92.2219C60.7688 94.7344 54.4781 96 48 96Z' fill='%2327ACA1'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  animation: rotate 2s linear infinite;
}

.gs_ajax_loader.gs_button--primary::before {
  background-image: url("data:image/svg+xml,%3Csvg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 96C41.5219 96 35.2313 94.7344 29.3156 92.2312C23.5969 89.8125 18.4688 86.3438 14.0625 81.9375C9.65625 77.5312 6.1875 72.4031 3.76875 66.6844C1.26563 60.7687 0 54.4781 0 48C0 46.1344 1.50938 44.625 3.375 44.625C5.24063 44.625 6.75 46.1344 6.75 48C6.75 53.5687 7.8375 58.9688 9.99375 64.0594C12.075 68.9719 15.0469 73.3875 18.8344 77.175C22.6219 80.9625 27.0375 83.9437 31.95 86.0156C37.0313 88.1625 42.4313 89.25 48 89.25C53.5688 89.25 58.9688 88.1625 64.0594 86.0063C68.9719 83.925 73.3875 80.9531 77.175 77.1656C80.9625 73.3781 83.9438 68.9625 86.0156 64.05C88.1625 58.9688 89.25 53.5687 89.25 48C89.25 42.4313 88.1625 37.0312 86.0063 31.9406C83.9321 27.0399 80.9301 22.5864 77.1656 18.825C73.4084 15.0555 68.9538 12.0528 64.05 9.98438C58.9688 7.8375 53.5688 6.75 48 6.75C46.1344 6.75 44.625 5.24062 44.625 3.375C44.625 1.50938 46.1344 0 48 0C54.4781 0 60.7688 1.26562 66.6844 3.76875C72.4031 6.1875 77.5313 9.65625 81.9375 14.0625C86.3438 18.4688 89.8031 23.6063 92.2219 29.3156C94.725 35.2313 95.9906 41.5219 95.9906 48C95.9906 54.4781 94.725 60.7687 92.2219 66.6844C89.8125 72.4031 86.3438 77.5312 81.9375 81.9375C77.5313 86.3438 72.3938 89.8031 66.6844 92.2219C60.7688 94.7344 54.4781 96 48 96Z' fill='white'/%3E%3C/svg%3E%0A");
}

.gs_push_notification {
  position: fixed;
  right: -300px;
  bottom: 0;
  opacity: 0;
  background: linear-gradient(180deg, #26C1B5 0%, #25A59B 100%);
  user-select: none;
  color: white;
  border-radius: 6px;
  width: auto;
  max-width: 80%;
  margin: 12px;
  padding: 12px;
  z-index: 99;
  animation: slide 0.25s ease forwards;
  display: none;
}
@media screen and (max-width: 500px) {
  .gs_push_notification {
    padding: 8px;
  }
}
.gs_push_notification--active {
  display: block;
}

.call_to_action {
  position: relative;
  display: block;
  width: 100%;
  margin: 60px 0;
  padding-left: 200px;
  padding-right: 100px;
}
@media screen and (max-width: 768px) {
  .call_to_action {
    padding-left: 150px;
    padding-right: 70px;
  }
}
@media screen and (max-width: 500px) {
  .call_to_action {
    border: 1px solid #CFD7DC;
    padding-left: 16px;
    margin: 16px 0;
  }
}
.call_to_action:hover, .call_to_action:focus-visible {
  color: #27ACA1;
}
.call_to_action::before, .call_to_action::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translateY(-50%);
  top: 50%;
}
.call_to_action::before {
  background-image: url("/static/build/img/call_to_action.png");
  width: 200px;
  height: 176px;
  left: 0;
}
@media screen and (max-width: 768px) {
  .call_to_action::before {
    width: 150px;
  }
}
@media screen and (max-width: 500px) {
  .call_to_action::before {
    display: none;
  }
}
.call_to_action::after {
  background-image: url("data:image/svg+xml,%3Csvg width='94' height='94' viewBox='0 0 94 94' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='94' height='94' rx='47' fill='white'/%3E%3Cpath d='M50 62L68 46.9994L50.0016 32' stroke='%2327ACA1' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M68 47H26' stroke='%2327ACA1' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E");
  width: 60px;
  height: 60px;
  right: 24px;
}
@media screen and (max-width: 768px) {
  .call_to_action::after {
    width: 45px;
    height: 45px;
    right: 16px;
  }
}

.call_to_action--secondary::before {
  background-image: url("/static/build/img/call_to_action--secondary.png");
}

.gs_education_step {
  display: flex;
  flex-direction: column;
  width: calc(100% - 136px);
}
@media screen and (max-width: 1024px) {
  .gs_education_step {
    width: calc(100% - 68px);
  }
}
@media screen and (max-width: 500px) {
  .gs_education_step {
    width: 100%;
  }
}
.gs_education_step > * + * {
  margin-top: 16px;
}
.gs_education_step:nth-of-type(even) {
  margin-left: auto;
}
.gs_education_step__header {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 500px) {
  .gs_education_step__header {
    align-items: flex-start;
    flex-direction: column;
  }
}
.gs_education_step__header > * + * {
  margin-left: 24px;
}
@media screen and (max-width: 500px) {
  .gs_education_step__header > * + * {
    margin-left: 0;
    margin-top: 16px;
  }
}
.gs_education_step__number {
  font-weight: 500;
  color: white;
  background-color: #27ACA1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
}
.gs_education_step__number {
  font-size: 18px;
  line-height: 22px;
}
@media screen and (min-width: 500px) {
  .gs_education_step__number {
    font-size: calc(18px + 10 * ((100vw - 500px) / 940));
    line-height: calc(22px + 18 * ((100vw - 500px) / 940));
  }
}
@media screen and (min-width: 1440px) {
  .gs_education_step__number {
    font-size: 28px;
    line-height: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .gs_education_step__number {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 768px) {
  .gs_education_step__number {
    width: 48px;
    height: 48px;
  }
}
.gs_education_step__description {
  display: flex;
  flex-direction: column;
}
.gs_education_step__description > * + * {
  margin-top: 8px;
}
.gs_education_step__images {
  display: flex;
}
.gs_education_step__images > * {
  width: 100%;
}
.gs_education_step__images > * + * {
  margin-left: 8px;
}
.gs_education_step img {
  margin: 0 auto;
  max-height: 400px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top left;
  border-radius: 8px;
}

.gs_activity {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}
@media screen and (max-width: 768px) {
  .gs_activity {
    flex-direction: column;
    align-items: flex-start;
  }
}
.gs_activity > * + * {
  margin-left: 16px;
}
@media screen and (max-width: 768px) {
  .gs_activity > * + * {
    margin-left: 0;
    margin-top: 16px;
  }
}
.gs_activity .gs_link--stretched + * {
  margin-left: 0;
  margin-top: 0;
}
@media screen and (max-width: 768px) {
  .gs_activity .gs_button {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .gs_activity svg {
    width: 24px;
    height: 24px;
  }
}
.gs_activity path {
  transition: 0.25s ease;
}
.gs_activity .gs_link--stretched:hover ~ .gs_button,
.gs_activity .gs_link--stretched:focus-visible ~ .gs_button {
  color: #04665E;
}
.gs_activity .gs_link--stretched:active ~ .gs_button {
  color: #0C0C3D;
}
.gs_activity .gs_link--stretched:hover ~ .d_flex path,
.gs_activity .gs_link--stretched:focus-visible ~ .d_flex path {
  fill: #04665E;
}
.gs_activity .gs_link--stretched:active ~ .d_flex path {
  fill: #0C0C3D;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #27ACA1;
  border-radius: 8px;
  width: 200px;
  height: 200px;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}
@media screen and (max-width: 500px) {
  .avatar {
    width: 96px;
    height: 96px;
  }
}

.user_document {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.user_document > * + * {
  margin-top: 16px;
}
.user_document .gs_link--stretched {
  border-radius: 16px;
  border: 1px solid transparent;
}
.user_document .gs_link--stretched:hover,
.user_document .gs_link--stretched:focus-visible {
  border-color: #CFD7DC;
}
.user_document .gs_link--stretched + * {
  margin-top: 0;
}
.user_document .file_link {
  border: 1px solid transparent;
  border-radius: 50%;
}
.user_document .file_link:hover,
.user_document .file_link:focus-visible {
  border-color: #CFD7DC;
}
.user_document .file_name {
  margin-bottom: 16px;
}
.user_document .buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
}
.user_document .buttons > * + * {
  margin-top: 8px;
}
@media screen and (max-width: 768px) {
  .user_document svg {
    width: 96px;
    height: 96px;
  }
}

.legal_service_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s ease;
}
.legal_service_card.active {
  background: #27ACA1;
  color: white;
}
.legal_service_card > * + * {
  margin-top: 32px;
}
@media screen and (max-width: 768px) {
  .legal_service_card > * + * {
    margin-top: 16px;
  }
}
.legal_service_card input[type=hidden] + * {
  margin-top: 0;
}

.news_detail {
  display: flex;
  flex-direction: column;
}
.news_detail > * + * {
  margin-top: 16px;
}
.news_detail .preview {
  border-radius: 16px;
}

.gs_form input,
.gs_form textarea,
.gs_form select {
  outline: none;
  width: 100%;
  background-color: white;
  border: 1px solid #CFD7DC;
  border-radius: 8px;
  padding: 16px;
  transition: border 0.25s ease;
}
@media screen and (max-width: 768px) {
  .gs_form input,
  .gs_form textarea,
  .gs_form select {
    padding: 14px 16px;
  }
}
.gs_form__group {
  position: relative;
}
.gs_form__group label {
  position: absolute;
  top: 6px;
  left: 16px;
  color: #5D647D;
  pointer-events: none;
  transition: 0.25s ease;
  font-size: 12px;
  line-height: 16px;
}
@media screen and (max-width: 768px) {
  .gs_form__group label {
    top: 4px;
  }
}
.gs_form__group--textarea::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: 25px;
  background-color: white;
  border-radius: 8px 8px 0 0;
}
.gs_form__group--required::before {
  content: "";
  position: absolute;
  top: -4px;
  left: 16px;
  width: 8px;
  height: 8px;
  background: white url("data:image/svg+xml,%3Csvg width='7' height='8' viewBox='0 0 7 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.056 1.778L6.866 3.218L4.4 4.136L6.866 5.054L6.038 6.53L3.986 4.91L4.364 7.52H2.708L3.05 4.91L0.998 6.566L0.134 5.036L2.582 4.118L0.134 3.236L0.962 1.76L3.068 3.38L2.708 0.751999H4.382L3.986 3.38L6.056 1.778Z' fill='%23EA4C4C'/%3E%3C/svg%3E") no-repeat center/contain;
}
.gs_form__group--password input {
  padding-right: 42px;
}
.gs_form__group input, .gs_form__group textarea {
  padding: 24px 16px 8px;
}
.gs_form__group:not(:has(label)) input, .gs_form__group:not(:has(label)) textarea {
  padding: 8px 16px;
}
.gs_form__group input:placeholder-shown, .gs_form__group textarea:placeholder-shown {
  padding: 16px;
}
.gs_form__group input:placeholder-shown + label, .gs_form__group textarea:placeholder-shown + label {
  position: absolute;
  transform: translateY(-50%);
  top: 30px;
  font-size: inherit;
  line-height: inherit;
}
@media screen and (max-width: 768px) {
  .gs_form__group input:placeholder-shown + label, .gs_form__group textarea:placeholder-shown + label {
    top: 24px;
  }
}
.gs_form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6L8.00022 11L13 6.00043' stroke='%230C0C3D' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position-y: center;
  background-position-x: calc(100% - 16px);
  padding-right: 42px;
}
@media screen and (max-width: 500px) {
  .gs_form select {
    background-size: 16px;
  }
}
.gs_form option {
  color: #5D647D;
}
.gs_form input {
  height: 60px;
}
@media screen and (max-width: 768px) {
  .gs_form input {
    height: 50px;
  }
}
.gs_form textarea {
  min-height: 105px;
}
.gs_form input::placeholder,
.gs_form textarea::placeholder {
  visibility: hidden;
  opacity: 0;
}
.gs_form input:focus-visible,
.gs_form textarea:focus-visible,
.gs_form input:focus,
.gs_form textarea:focus {
  border-color: #27ACA1;
}
.gs_form input:disabled {
  background-color: #F6F8FA;
}
.gs_form input:-webkit-autofill,
.gs_form input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #DCF7F4 inset;
  -webkit-text-fill-color: #0C0C3D;
}
.gs_form fieldset {
  display: flex;
  flex-direction: column;
}
.gs_form fieldset > * + *,
.gs_form fieldset + p {
  margin-top: 12px;
}
.gs_form .errors {
  color: #FF253F;
  font-size: 12px;
  line-height: 16px;
}
.gs_form .errors p {
  margin-bottom: 6px;
}
.gs_form .gs_modal__body .errors + * {
  margin-top: 0;
}
@-moz-document url-prefix() {
  .gs_form .gs_form__group--textarea::before {
    top: 2px;
  }
}

input[type=search] {
  outline: none;
  width: 100%;
  background-color: white;
  border: 1px solid #CFD7DC;
  border-radius: 8px;
  padding: 16px;
  transition: border 0.25s ease;
}
@media screen and (max-width: 768px) {
  input[type=search] {
    padding: 14px 16px;
  }
}

input[type=search]::-webkit-search-cancel-button {
  display: none;
}

input[type=search]:focus-visible,
input[type=search]:focus {
  border-color: #27ACA1;
}

.gs_radio {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
}
@media screen and (max-width: 500px) {
  .gs_radio {
    padding: 10px;
  }
}
.gs_radio > *:not(label) {
  pointer-events: none;
  z-index: 1;
}
.gs_radio > * + * {
  margin-left: 8px;
}
.gs_radio > *:last-child {
  margin-left: auto;
}
.gs_radio input {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  margin: 0;
  opacity: 0;
}
.gs_radio input:checked + label::before,
.gs_radio input:focus-visible:not(:checked) + label::before,
.gs_radio label:hover::before {
  opacity: 1;
}
.gs_radio input:checked + label,
.gs_radio input:focus-visible:not(:checked) + label,
.gs_radio label:hover {
  border-color: #52BDB4;
}
.gs_radio input:checked ~ .circle {
  background-color: white;
}
.gs_radio input:disabled + label {
  pointer-events: none;
  background-color: #BFC7D7;
}
.gs_radio input:disabled + label::before {
  display: none;
}
.gs_radio input:disabled ~ .circle::before {
  background-color: transparent;
}
.gs_radio label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #DCF7F4;
  transition: 0.25s ease;
  cursor: pointer;
}
.gs_radio label::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #52BDB4;
  transition: 0.25s ease;
  opacity: 0;
}
.gs_radio .circle {
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  transition: 0.25s ease;
}
@media screen and (max-width: 500px) {
  .gs_radio .circle {
    width: 15px;
    height: 15px;
  }
}
.gs_radio .circle::before {
  content: "";
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #27ACA1;
}
@media screen and (max-width: 500px) {
  .gs_radio .circle::before {
    width: 6px;
    height: 6px;
  }
}

.gs_checkbox {
  position: relative;
  display: flex;
}
.gs_checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  margin: 0;
  opacity: 0;
}
.gs_checkbox input:checked + label::before,
.gs_checkbox input:focus:not(:checked) + label::before,
.gs_checkbox label:hover::before {
  background-color: #27ACA1;
  border-color: #27ACA1;
}
.gs_checkbox input:checked + label::after {
  opacity: 1;
}
.gs_checkbox input:disabled + label {
  pointer-events: none;
  color: #BFC7D7;
}
.gs_checkbox input:disabled + label::before {
  background-color: #BFC7D7;
}
.gs_checkbox label {
  cursor: pointer;
  position: relative;
  padding-left: 28px;
}
.gs_checkbox label::before,
.gs_checkbox label::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  transition: 0.25s ease;
}
.gs_checkbox label::before {
  border: 1.5px solid #BFC7D7;
  border-radius: 4px;
}
.gs_checkbox label::after {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 10L0 5.19231L1.4 3.84615L5 7.30769L12.6 0L14 1.34615L5 10Z' fill='white'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  opacity: 0;
}

.gs_checkbox--white input:checked + label::before,
.gs_checkbox--white input:focus:not(:checked) + label::before,
.gs_checkbox--white label:hover::before {
  background-color: white;
  border-color: white;
}
.gs_checkbox--white label::before {
  border-color: #DCF7F4;
}
.gs_checkbox--white label::after {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 10L0 5.19231L1.4 3.84615L5 7.30769L12.6 0L14 1.34615L5 10Z' fill='%2327ACA1'/%3E%3C/svg%3E%0A");
}

.pass_visibility {
  display: block;
  width: 52px;
  height: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  outline: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 9.00462C14.2091 9.00462 16 10.7955 16 13.0046C16 15.2138 14.2091 17.0046 12 17.0046C9.79086 17.0046 8 15.2138 8 13.0046C8 10.7955 9.79086 9.00462 12 9.00462ZM12 10.5046C10.6193 10.5046 9.5 11.6239 9.5 13.0046C9.5 14.3853 10.6193 15.5046 12 15.5046C13.3807 15.5046 14.5 14.3853 14.5 13.0046C14.5 11.6239 13.3807 10.5046 12 10.5046ZM12 5.5C16.6135 5.5 20.5961 8.65001 21.7011 13.0644C21.8017 13.4662 21.5575 13.8735 21.1557 13.9741C20.7539 14.0746 20.3466 13.8305 20.246 13.4286C19.3071 9.67796 15.9214 7 12 7C8.07693 7 4.6901 9.68026 3.75286 13.4332C3.65249 13.835 3.24535 14.0794 2.84348 13.9791C2.44161 13.8787 2.19719 13.4716 2.29755 13.0697C3.40065 8.65272 7.38448 5.5 12 5.5Z' fill='%230C0C3D'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  transition: 0.25s ease;
  pointer-events: none;
  opacity: 0;
}

.pass_visibility--visible {
  pointer-events: initial;
  opacity: 1;
}

.pass_visibility--active {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.21967 2.21967C1.9534 2.48594 1.9292 2.9026 2.14705 3.19621L2.21967 3.28033L6.25424 7.3149C4.33225 8.66437 2.89577 10.6799 2.29888 13.0644C2.1983 13.4662 2.4425 13.8735 2.84431 13.9741C3.24613 14.0746 3.6534 13.8305 3.75399 13.4286C4.28346 11.3135 5.59112 9.53947 7.33416 8.39452L9.14379 10.2043C8.43628 10.9258 8 11.9143 8 13.0046C8 15.2138 9.79086 17.0046 12 17.0046C13.0904 17.0046 14.0788 16.5683 14.8004 15.8608L20.7197 21.7803C21.0126 22.0732 21.4874 22.0732 21.7803 21.7803C22.0466 21.5141 22.0708 21.0974 21.8529 20.8038L21.7803 20.7197L15.6668 14.6055L15.668 14.604L14.4679 13.4061L11.598 10.5368L11.6 10.536L8.71877 7.65782L8.72 7.656L7.58672 6.52549L3.28033 2.21967C2.98744 1.92678 2.51256 1.92678 2.21967 2.21967ZM10.2041 11.2655L13.7392 14.8006C13.2892 15.2364 12.6759 15.5046 12 15.5046C10.6193 15.5046 9.5 14.3853 9.5 13.0046C9.5 12.3287 9.76824 11.7154 10.2041 11.2655ZM12 5.5C10.9997 5.5 10.0291 5.64807 9.11109 5.925L10.3481 7.16119C10.8839 7.05532 11.4364 7 12 7C15.9231 7 19.3099 9.68026 20.2471 13.4332C20.3475 13.835 20.7546 14.0794 21.1565 13.9791C21.5584 13.8787 21.8028 13.4716 21.7024 13.0697C20.5994 8.65272 16.6155 5.5 12 5.5ZM12.1947 9.00928L15.996 12.81C15.8942 10.7531 14.2472 9.10764 12.1947 9.00928Z' fill='%230C0C3D'/%3E%3C/svg%3E");
}

.event_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.event_card__header {
  margin-bottom: 64px;
}
@media screen and (max-width: 1024px) {
  .event_card__header {
    margin-bottom: 40px;
  }
}
.event_card__speakers {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 24px;
}
@media screen and (max-width: 1024px) {
  .event_card__speakers {
    margin-bottom: 15px;
  }
}
.event_card__speakers li {
  display: flex;
}
.event_card__speakers > li + li {
  margin-top: 8px;
}
.event_card__speakers img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-right: 16px;
}
@media screen and (max-width: 1024px) {
  .event_card__speakers img {
    width: 64px;
    height: 64px;
    border-radius: 5px;
    margin-right: 10px;
  }
}
.event_card .gs_label {
  margin-bottom: 16px;
}
.event_card .gs_link, .event_card .faq_card__title, .event_card .supporters__link, .event_card .call_to_action {
  border: 1px solid transparent;
  border-radius: 16px;
}
.event_card .gs_link:hover, .event_card .faq_card__title:hover, .event_card .supporters__link:hover, .event_card .call_to_action:hover,
.event_card .gs_link:focus-visible,
.event_card .faq_card__title:focus-visible,
.event_card .supporters__link:focus-visible,
.event_card .call_to_action:focus-visible {
  border-color: #CFD7DC;
}

.event_card--promo {
  justify-content: flex-start;
}
.event_card--promo .gs_label,
.event_card--promo .event_card__speakers {
  margin-bottom: 0;
}
.event_card--promo .gs_label + .event_card__speakers {
  margin-top: 16px;
}

.service_card {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.service_card img {
  object-fit: cover;
  object-position: left;
  border-radius: 16px 16px 0 0;
  width: 100%;
  height: 110px;
  min-height: 176px;
}
@media screen and (max-width: 768px) {
  .service_card img {
    min-height: 146px;
  }
}
.service_card .h3, .service_card .promo_benefit_card__title, .service_card .faq_card__title {
  transition: 0.25s ease;
}
.service_card__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .service_card__body {
    padding: 12px 16px;
  }
}
.service_card__body > * + * {
  margin-top: 16px;
}
.service_card .gs_link--stretched:hover ~ .service_card__body .h3, .service_card .gs_link--stretched:hover ~ .service_card__body .promo_benefit_card__title, .service_card .gs_link--stretched:hover ~ .service_card__body .faq_card__title,
.service_card .gs_link--stretched:focus-visible ~ .service_card__body .h3,
.service_card .gs_link--stretched:focus-visible ~ .service_card__body .promo_benefit_card__title,
.service_card .gs_link--stretched:focus-visible ~ .service_card__body .faq_card__title {
  color: #04665E;
}

.knowledgebase_card {
  position: relative;
  display: flex;
  flex-direction: column;
}
.knowledgebase_card > * + * {
  margin-top: 8px;
}
.knowledgebase_card .gs_link--stretched + * {
  margin-top: 0;
}
.knowledgebase_card img {
  border: 1px solid #F6F8FA;
  object-fit: cover;
  object-position: left;
  border-radius: 16px;
  width: 100%;
  height: 200px;
}
@media screen and (max-width: 500px) {
  .knowledgebase_card img {
    height: 170px;
  }
}
.knowledgebase_card .h3, .knowledgebase_card .promo_benefit_card__title, .knowledgebase_card .faq_card__title {
  font: inherit;
  transition: 0.25s ease;
}
.knowledgebase_card .gs_link--stretched:hover ~ .h3, .knowledgebase_card .gs_link--stretched:hover ~ .promo_benefit_card__title, .knowledgebase_card .gs_link--stretched:hover ~ .faq_card__title,
.knowledgebase_card .gs_link--stretched:focus-visible ~ .h3,
.knowledgebase_card .gs_link--stretched:focus-visible ~ .promo_benefit_card__title,
.knowledgebase_card .gs_link--stretched:focus-visible ~ .faq_card__title {
  color: #04665E;
}
.knowledgebase_card--centered img {
  object-position: center;
}

.event_speaker_card {
  display: flex;
}
@media screen and (max-width: 1024px) {
  .event_speaker_card {
    flex-direction: column;
  }
}
.event_speaker_card > * + * {
  margin-left: 32px;
}
@media screen and (max-width: 1024px) {
  .event_speaker_card > * + * {
    margin-left: 0;
    margin-top: 16px;
  }
}
.event_speaker_card img {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  flex-shrink: 0;
}
@media screen and (max-width: 1024px) {
  .event_speaker_card img {
    width: 150px;
    height: 150px;
  }
}
.event_speaker_card .h3, .event_speaker_card .promo_benefit_card__title, .event_speaker_card .faq_card__title {
  margin-right: 32px;
}
@media screen and (max-width: 1024px) {
  .event_speaker_card .h3, .event_speaker_card .promo_benefit_card__title, .event_speaker_card .faq_card__title {
    margin-right: 0;
  }
}
.event_speaker_card__description {
  flex: 0 0 50%;
  margin-left: auto;
}
@media screen and (max-width: 1024px) {
  .event_speaker_card__description {
    flex: auto;
    margin-left: 0;
  }
}

.person_card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px 20px;
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  .person_card {
    margin-top: 68px;
  }
}
.person_card > *:not(:last-child) {
  margin-bottom: 16px;
}
.person_card img {
  width: 160px;
  height: 160px;
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
  margin-top: -80px;
}
@media screen and (max-width: 768px) {
  .person_card img {
    width: 136px;
    height: 136px;
    margin-top: -68px;
  }
}
.person_card .bottom_link {
  margin-top: auto;
}

.faq_card {
  padding: 0;
}
.faq_card + .faq_card {
  margin-top: 16px;
}
.faq_card.active .faq_card__title::before {
  transform: translateY(-50%) rotate(-180deg);
}
.faq_card__title {
  width: 100%;
  position: relative;
  text-align: left;
  padding: 24px;
  padding-right: 48px;
  transition: 0.25s ease;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .faq_card__title {
    padding: 16px;
    padding-right: 40px;
  }
}
.faq_card__title::before {
  content: "";
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 24px;
  width: 24px;
  height: 24px;
  background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38 16L23.9994 32L10 16.0014' stroke='%230C0C3D' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat center/contain;
  transition: 0.25s ease;
}
@media screen and (max-width: 768px) {
  .faq_card__title::before {
    right: 16px;
  }
}
.faq_card__body {
  padding: 0 24px 24px;
  display: none;
}
@media screen and (max-width: 768px) {
  .faq_card__body {
    padding: 0 16px 16px;
  }
}

.review_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.review_card img {
  cursor: zoom-in;
  text-align: center;
  width: 100%;
  height: 100%;
  border: 1px solid #CFD7DC;
  border-radius: 16px;
  margin-bottom: 8px;
}
@media screen and (max-width: 1024px) {
  .review_card img {
    max-width: 350px;
  }
}
.review_card > * + * {
  margin-top: 8px;
}

.news_card {
  position: relative;
  display: flex;
  flex-direction: column;
}
.news_card > * + * {
  margin-top: 8px;
}
.news_card .gs_link--stretched + * {
  margin-top: 0;
  margin-left: 0;
}
.news_card img {
  border: 1px solid #F6F8FA;
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
  width: 100%;
  height: 200px;
}
@media screen and (max-width: 500px) {
  .news_card img {
    height: 170px;
  }
}
.news_card__body {
  display: flex;
  flex-direction: column;
}
.news_card__body > * + * {
  margin-top: 8px;
}
.news_card .h3, .news_card .promo_benefit_card__title, .news_card .faq_card__title {
  font: inherit;
  transition: 0.25s ease;
}
.news_card .gs_link--stretched:hover ~ .news_card__body .h3, .news_card .gs_link--stretched:hover ~ .news_card__body .promo_benefit_card__title, .news_card .gs_link--stretched:hover ~ .news_card__body .faq_card__title,
.news_card .gs_link--stretched:focus-visible ~ .news_card__body .h3,
.news_card .gs_link--stretched:focus-visible ~ .news_card__body .promo_benefit_card__title,
.news_card .gs_link--stretched:focus-visible ~ .news_card__body .faq_card__title {
  color: #04665E;
}

.news_card--first_item {
  grid-column: 1/-1;
  flex-direction: row;
}
@media screen and (max-width: 1024px) {
  .news_card--first_item {
    grid-column: initial;
    flex-direction: column;
  }
}
.news_card--first_item > * {
  flex: 1;
}
@media screen and (max-width: 1024px) {
  .news_card--first_item > * {
    flex: auto;
  }
}
.news_card--first_item > * + * {
  margin-left: 16px;
}
@media screen and (max-width: 1024px) {
  .news_card--first_item > * + * {
    margin-left: 0;
  }
}
.news_card--first_item img {
  height: 400px;
}
@media screen and (max-width: 1024px) {
  .news_card--first_item img {
    height: 200px;
  }
}
@media screen and (max-width: 500px) {
  .news_card--first_item img {
    height: 170px;
  }
}
.news_card--first_item .h3, .news_card--first_item .promo_benefit_card__title, .news_card--first_item .faq_card__title {
  font-weight: 500;
}
.news_card--first_item .h3, .news_card--first_item .promo_benefit_card__title, .news_card--first_item .faq_card__title {
  font-size: 15px;
  line-height: 20px;
}
@media screen and (min-width: 500px) {
  .news_card--first_item .h3, .news_card--first_item .promo_benefit_card__title, .news_card--first_item .faq_card__title {
    font-size: calc(15px + 9 * ((100vw - 500px) / 940));
    line-height: calc(20px + 12 * ((100vw - 500px) / 940));
  }
}
@media screen and (min-width: 1440px) {
  .news_card--first_item .h3, .news_card--first_item .promo_benefit_card__title, .news_card--first_item .faq_card__title {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 1024px) {
  .news_card--first_item .h3, .news_card--first_item .promo_benefit_card__title, .news_card--first_item .faq_card__title {
    font: inherit;
  }
}
.news_card--first_item .news_content * {
  text-align: left !important;
}
.news_card--first_item .gs_link--colored {
  margin-top: auto;
}

.benefit_card {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
}
.benefit_card__header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 60px;
}
@media screen and (max-width: 768px) {
  .benefit_card__header {
    padding: 0 16px;
    height: 50px;
  }
}
.benefit_card__track {
  background-image: url("data:image/svg+xml,%3Csvg width='512' height='58' viewBox='0 0 512 58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-0.290104 0L33.0519 57.75H-33.6321L-0.290104 0Z' fill='white'/%3E%3Cpath d='M42.3939 57.75L9.05188 0L75.7358 0L42.3939 57.75Z' fill='white'/%3E%3Cpath d='M85.0778 0L118.42 57.75H51.7358L85.0778 0Z' fill='white'/%3E%3Cpath d='M127.762 57.75L94.4198 0L161.104 0L127.762 57.75Z' fill='white'/%3E%3Cpath d='M170.446 0L203.788 57.75H137.104L170.446 0Z' fill='white'/%3E%3Cpath d='M213.13 57.75L179.788 0L246.472 0L213.13 57.75Z' fill='white'/%3E%3Cpath d='M255.814 0L289.156 57.75H222.472L255.814 0Z' fill='white'/%3E%3Cpath d='M298.498 57.75L265.156 0L331.84 0L298.498 57.75Z' fill='white'/%3E%3Cpath d='M341.182 0L374.524 57.75H307.84L341.182 0Z' fill='white'/%3E%3Cpath d='M383.866 57.75L350.524 0L417.208 0L383.866 57.75Z' fill='white'/%3E%3Cpath d='M426.549 0L459.891 57.75H393.208L426.549 0Z' fill='white'/%3E%3Cpath d='M469.233 57.75L435.891 0L502.575 0L469.233 57.75Z' fill='white'/%3E%3Cpath d='M511.917 0L545.259 57.75H478.575L511.917 0Z' fill='white'/%3E%3C/svg%3E") !important;
  background-repeat: repeat-x !important;
  background-position: center !important;
  background-size: auto !important;
  background-color: initial !important;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: calc(100% - 22px);
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .benefit_card__track {
    background-image: url("data:image/svg+xml,%3Csvg width='320' height='37' viewBox='0 0 320 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-0.181282 0.875L20.6575 36.9688H-21.02L-0.181282 0.875Z' fill='white'/%3E%3Cpath d='M26.4962 36.9688L5.65748 0.874996L47.335 0.874996L26.4962 36.9688Z' fill='white'/%3E%3Cpath d='M53.1737 0.875L74.0124 36.9688H32.335L53.1737 0.875Z' fill='white'/%3E%3Cpath d='M79.8512 36.9688L59.0125 0.874996L100.69 0.874996L79.8512 36.9688Z' fill='white'/%3E%3Cpath d='M106.529 0.875L127.367 36.9688H85.6899L106.529 0.875Z' fill='white'/%3E%3Cpath d='M133.206 36.9688L112.367 0.874996L154.045 0.874996L133.206 36.9688Z' fill='white'/%3E%3Cpath d='M159.884 0.875L180.722 36.9688H139.045L159.884 0.875Z' fill='white'/%3E%3Cpath d='M186.561 36.9688L165.722 0.874996L207.4 0.874996L186.561 36.9688Z' fill='white'/%3E%3Cpath d='M213.239 0.875L234.077 36.9688H192.4L213.239 0.875Z' fill='white'/%3E%3Cpath d='M239.916 36.9688L219.077 0.874996L260.755 0.874996L239.916 36.9688Z' fill='white'/%3E%3Cpath d='M266.593 0.875L287.432 36.9688H245.755L266.593 0.875Z' fill='white'/%3E%3Cpath d='M293.271 36.9688L272.432 0.874996L314.11 0.874996L293.271 36.9688Z' fill='white'/%3E%3Cpath d='M319.948 0.875L340.787 36.9688H299.11L319.948 0.875Z' fill='white'/%3E%3C/svg%3E") !important;
    background-repeat: repeat-x !important;
    background-position: center !important;
    background-size: auto !important;
    background-color: initial !important;
  }
}
@media screen and (max-width: 768px) {
  .benefit_card__track {
    height: calc(100% - 12px);
  }
}
.benefit_card__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #27ACA1;
  color: white;
  border-radius: 96px;
  width: 60px;
  height: 60px;
  z-index: 1;
  padding: 12px;
}
@media screen and (max-width: 768px) {
  .benefit_card__icon {
    width: 50px;
    height: 50px;
  }
}
.benefit_card__icon svg, .benefit_card__icon img {
  width: 100%;
  height: 100%;
}
.benefit_card__icon img {
  object-fit: contain;
}
.benefit_card__number {
  width: auto;
  min-width: 60px;
}
@media screen and (max-width: 768px) {
  .benefit_card__number {
    min-width: 50px;
  }
}
.benefit_card__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 20px 24px 0;
}
@media screen and (max-width: 768px) {
  .benefit_card__content {
    padding: 12px 16px 0;
  }
}
.benefit_card__content > * + * {
  margin-top: 12px;
}

.promo_benefit_card {
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 0;
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .promo_benefit_card {
    margin-top: 25px;
  }
}
.promo_benefit_card__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #27ACA1;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 12px;
  margin-top: -30px;
  margin-bottom: 16px;
}
@media screen and (max-width: 768px) {
  .promo_benefit_card__icon {
    width: 50px;
    height: 50px;
    margin-top: -25px;
  }
}
.audience_card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.audience_card > * + * {
  margin-top: 8px;
}
.audience_card img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
@media screen and (max-width: 1024px) {
  .audience_card img {
    width: 96px;
    height: 96px;
  }
}

.package_card {
  background-color: transparent;
  border: 1px solid #CFD7DC;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.package_card:nth-of-type(even) {
  background-color: #F6F8FA;
}
.package_card__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .package_card__body {
    padding: 12px 16px;
  }
}
.package_card__body > * + * {
  margin-top: 16px;
}
.package_card img {
  object-fit: cover;
  object-position: center;
  border-radius: 16px 16px 0 0;
  width: 100%;
  height: 120px;
  min-height: 120px;
}
.package_card .h3, .package_card .faq_card__title, .package_card .promo_benefit_card__title {
  transition: 0.25s ease;
}
.package_card .gs_list--dots {
  font-size: 14px;
  line-height: 1.4;
  color: #5D647D;
  margin-bottom: auto;
}
.package_card .old_price {
  font-size: 18px;
  line-height: 1;
  color: #CFD7DC;
  text-decoration: line-through;
}
.package_card .new_price {
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  color: #27ACA1;
}
.package_card .request_price {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  color: #27ACA1;
}

.active_event_card {
  display: flex;
  flex-direction: column;
  min-height: 305px;
}
@media screen and (max-width: 768px) {
  .active_event_card {
    min-height: 205px;
  }
}
.active_event_card > * + * {
  margin-top: 16px;
}
.active_event_card .h3, .active_event_card .faq_card__title, .active_event_card .promo_benefit_card__title {
  margin-bottom: 16px;
}
.active_event_card__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.active_event_card__footer {
  display: flex;
  margin-top: auto;
}
.active_event_card__header > * + *, .active_event_card__footer > * + * {
  margin-left: 12px;
}
.active_event_card__labels {
  display: flex;
  flex-wrap: wrap;
}
.active_event_card__labels > * {
  margin-right: 6px;
  margin-bottom: 6px;
}
.active_event_card__trash {
  flex-shrink: 0;
  outline: none;
  background-color: transparent;
}
.active_event_card__trash svg {
  display: block;
}
.active_event_card__trash path {
  transition: 0.25s ease;
}
.active_event_card__trash:hover path, .active_event_card__trash:focus-visible path {
  fill: #27ACA1;
}

.slider-wrapper {
  overflow: hidden;
  width: calc(100% + 360px);
  margin-left: -180px;
}

.swiper {
  overflow: initial;
  width: calc(100% - 360px);
}

.swiper:not(.swiper-initialized) {
  opacity: 0;
}

.swiper.swiper-initialized {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.swiper-slide {
  transition: opacity 0.25s ease;
  height: auto;
}

.swiper-slide:not(.swiper-slide-visible) {
  user-select: none;
  pointer-events: none;
  opacity: 0.2;
}

.swiper-button-prev,
.swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 19L17 11.9997L9.00069 5' stroke='%230C0C3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: auto !important;
  background-color: white !important;
  outline: none;
  width: 56px;
  height: 56px;
  border: 1px solid rgba(207, 215, 220, 0.25);
  border-radius: 56px;
  box-shadow: 0px 28px 53px rgba(0, 25, 80, 0.08), 0px 10.2205px 15.1703px rgba(0, 25, 80, 0.0551985), 0px 4.96184px 8.87643px rgba(0, 25, 80, 0.0445033), 0px 2.43239px 6.24775px rgba(0, 25, 80, 0.0354967), 0px 0.96177px 3.59913px rgba(0, 25, 80, 0.0248015);
  margin: 0;
  transition: 0.25s ease;
}
.swiper-button-prev:hover, .swiper-button-prev:focus-visible,
.swiper-button-next:hover,
.swiper-button-next:focus-visible {
  border-color: #CFD7DC;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  transform: translateY(-50%) scaleX(-1);
  left: -15px;
}

.swiper-button-next {
  transform: translateY(-50%);
  right: -15px;
}

.swiper-button-disabled {
  display: none;
}

.marquee {
  overflow: hidden;
  width: calc(100% + 360px);
  margin-left: -180px;
}
.marquee .js-marquee-wrapper {
  display: flex;
}
.marquee .js-marquee {
  margin-right: 0 !important;
}
.marquee ul {
  display: flex;
  align-items: center;
}
.marquee .js-marquee + .js-marquee,
.marquee li + li {
  margin-left: 64px;
}
@media screen and (max-width: 500px) {
  .marquee .js-marquee + .js-marquee,
  .marquee li + li {
    margin-left: 32px;
  }
}
.marquee img {
  object-fit: contain;
  opacity: 0.85;
  transition: 0.25s ease;
}
@media screen and (max-width: 500px) {
  .marquee img {
    width: auto;
    height: 60px;
  }
}
.marquee a {
  outline: none;
}
.marquee a:hover img,
.marquee a:focus-visible img {
  opacity: 1;
}

.mfp-bg {
  background-color: rgba(6, 6, 37, 0.5);
}

.mfp-container {
  padding: 0 20px;
}

.mfp-fade::after {
  display: none;
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: 0.25s ease;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 1;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: 0.25s ease;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .mfp-title {
    display: none;
  }
}

.mfp-close {
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='60' height='60' rx='8' fill='white'/%3E%3Cpath d='M19 19L41.6274 41.6274' stroke='%235D647D' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M41.6274 19L19 41.6274' stroke='%235D647D' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  font-size: 0 !important;
  padding-right: 0 !important;
  top: 40px !important;
  right: -45px !important;
  opacity: 1 !important;
  cursor: pointer !important;
  width: 40px !important;
  height: 40px !important;
}
@media screen and (max-width: 768px) {
  .mfp-close {
    top: auto !important;
    bottom: 5px !important;
    right: 50% !important;
    transform: translateX(50%) !important;
    width: 30px !important;
    height: 30px !important;
  }
}

/* fade image in after load */
.lazyload,
.lazyloading {
  opacity: 0;
  filter: blur(10px);
}

.lazyloaded {
  opacity: 1;
  filter: none;
  transition: filter 0.25s, opacity 0.5s;
}

.datepicker--day-name,
.datepicker--cell.datepicker--cell-day.-current-,
.datepicker--button {
  color: #27ACA1;
}

.datepicker--cell.datepicker--cell-day.-selected-,
.datepicker--cell.datepicker--cell-day.-focus-,
.datepicker--button:hover {
  background-color: #DCF7F4;
  color: #0C0C3D;
}

.paginationjs {
  font-family: inherit;
}
@media screen and (max-width: 500px) {
  .paginationjs {
    justify-content: center;
  }
}
.paginationjs .paginationjs-pages ul {
  display: flex;
  align-items: center;
}
.paginationjs .paginationjs-pages ul li {
  float: initial;
  border: none !important;
  border-radius: 0 !important;
  margin: 0 8px;
}
@media screen and (max-width: 500px) {
  .paginationjs .paginationjs-pages ul li {
    margin: 0 4px;
  }
}
@media screen and (max-width: 375px) {
  .paginationjs .paginationjs-pages ul li {
    margin: 0 2px;
  }
}
.paginationjs .paginationjs-pages li a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 32px !important;
  height: 32px !important;
  font-size: 14px;
  line-height: 1;
  color: inherit;
  background: #F8FBFE;
  border: 1px solid #CFD7DC;
  border-radius: 50% !important;
  transition: 0.25s ease;
}
.paginationjs .paginationjs-pages li a:hover {
  background: none;
  border-color: #27ACA1;
}
.paginationjs .paginationjs-pages li.active a {
  background: #F8FBFE;
  border-color: #27ACA1;
  color: inherit;
}
.paginationjs .paginationjs-pages li.disabled a {
  pointer-events: none;
}
.paginationjs .paginationjs-pages .paginationjs-prev a,
.paginationjs .paginationjs-pages .paginationjs-next a {
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='12' viewBox='0 0 6 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.132584 0.913238C-0.0682506 0.687902 -0.0506176 0.342892 0.173181 0.140247C0.397423 -0.0615773 0.743526 -0.0434317 0.945146 0.180845L5.86679 5.6496C6.05368 5.85891 6.05368 6.17278 5.86679 6.38226L0.945896 11.8192C0.744071 12.0434 0.398105 12.0616 0.173932 11.8598C-0.0624766 11.6455 -0.0578632 11.2997 0.133334 11.0868L4.72439 6.01525L0.132584 0.913238Z' fill='black'/%3E%3C/svg%3E%0A") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: auto !important;
  font-size: 0 !important;
}
.paginationjs .paginationjs-pages .paginationjs-prev a {
  transform: scaleX(-1);
}