.socials {
    display: flex;

    &__item + &__item {
        margin-left: 8px;
    }

    &__item a {
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        background-color: $secondary;
        border-radius: $radius--medium;
        padding: 6px;
    }

    &__item path {
        transition: $transition;
    }

    &__item a:hover path,
    &__item a:focus-visible path {
        fill: $primary--hover;
    }

    &__item a:active path {
        fill: $primary--dark;
    }
}
