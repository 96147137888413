.service_card {
	@extend .gs_card;
	display: flex;
	flex-direction: column;
	padding: 0;

	img {
		object-fit: cover;
		object-position: left;
		border-radius: $radius $radius 0 0;
		width: 100%;
		height: 110px;
		min-height: 176px;

		@include max-width($tablet) {
			min-height: 146px;
		}
	}

	.h3 {
		transition: $transition;
	}

	&__body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		padding: 20px;

		@include max-width($tablet) {
			padding: 12px 16px;
		}
	}

	&__body > * + * {
		margin-top: 16px;
	}

	.gs_link--stretched:hover ~ .service_card__body .h3,
	.gs_link--stretched:focus-visible ~ .service_card__body .h3 {
		color: $primary--dark;
	}
}
