.gs_ajax_loader {
	color: transparent !important;
	transition: color 0s;
}

.gs_ajax_loader::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	background-image: url("data:image/svg+xml,%3Csvg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 96C41.5219 96 35.2313 94.7344 29.3156 92.2312C23.5969 89.8125 18.4688 86.3438 14.0625 81.9375C9.65625 77.5312 6.1875 72.4031 3.76875 66.6844C1.26563 60.7687 0 54.4781 0 48C0 46.1344 1.50938 44.625 3.375 44.625C5.24063 44.625 6.75 46.1344 6.75 48C6.75 53.5687 7.8375 58.9688 9.99375 64.0594C12.075 68.9719 15.0469 73.3875 18.8344 77.175C22.6219 80.9625 27.0375 83.9437 31.95 86.0156C37.0313 88.1625 42.4313 89.25 48 89.25C53.5688 89.25 58.9688 88.1625 64.0594 86.0063C68.9719 83.925 73.3875 80.9531 77.175 77.1656C80.9625 73.3781 83.9438 68.9625 86.0156 64.05C88.1625 58.9688 89.25 53.5687 89.25 48C89.25 42.4313 88.1625 37.0312 86.0063 31.9406C83.9321 27.0399 80.9301 22.5864 77.1656 18.825C73.4084 15.0555 68.9538 12.0528 64.05 9.98438C58.9688 7.8375 53.5688 6.75 48 6.75C46.1344 6.75 44.625 5.24062 44.625 3.375C44.625 1.50938 46.1344 0 48 0C54.4781 0 60.7688 1.26562 66.6844 3.76875C72.4031 6.1875 77.5313 9.65625 81.9375 14.0625C86.3438 18.4688 89.8031 23.6063 92.2219 29.3156C94.725 35.2313 95.9906 41.5219 95.9906 48C95.9906 54.4781 94.725 60.7687 92.2219 66.6844C89.8125 72.4031 86.3438 77.5312 81.9375 81.9375C77.5313 86.3438 72.3938 89.8031 66.6844 92.2219C60.7688 94.7344 54.4781 96 48 96Z' fill='%2327ACA1'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 30px;
	animation: rotate 2s linear infinite;
}

.gs_ajax_loader.gs_button--primary::before {
	background-image: url("data:image/svg+xml,%3Csvg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 96C41.5219 96 35.2313 94.7344 29.3156 92.2312C23.5969 89.8125 18.4688 86.3438 14.0625 81.9375C9.65625 77.5312 6.1875 72.4031 3.76875 66.6844C1.26563 60.7687 0 54.4781 0 48C0 46.1344 1.50938 44.625 3.375 44.625C5.24063 44.625 6.75 46.1344 6.75 48C6.75 53.5687 7.8375 58.9688 9.99375 64.0594C12.075 68.9719 15.0469 73.3875 18.8344 77.175C22.6219 80.9625 27.0375 83.9437 31.95 86.0156C37.0313 88.1625 42.4313 89.25 48 89.25C53.5688 89.25 58.9688 88.1625 64.0594 86.0063C68.9719 83.925 73.3875 80.9531 77.175 77.1656C80.9625 73.3781 83.9438 68.9625 86.0156 64.05C88.1625 58.9688 89.25 53.5687 89.25 48C89.25 42.4313 88.1625 37.0312 86.0063 31.9406C83.9321 27.0399 80.9301 22.5864 77.1656 18.825C73.4084 15.0555 68.9538 12.0528 64.05 9.98438C58.9688 7.8375 53.5688 6.75 48 6.75C46.1344 6.75 44.625 5.24062 44.625 3.375C44.625 1.50938 46.1344 0 48 0C54.4781 0 60.7688 1.26562 66.6844 3.76875C72.4031 6.1875 77.5313 9.65625 81.9375 14.0625C86.3438 18.4688 89.8031 23.6063 92.2219 29.3156C94.725 35.2313 95.9906 41.5219 95.9906 48C95.9906 54.4781 94.725 60.7687 92.2219 66.6844C89.8125 72.4031 86.3438 77.5312 81.9375 81.9375C77.5313 86.3438 72.3938 89.8031 66.6844 92.2219C60.7688 94.7344 54.4781 96 48 96Z' fill='white'/%3E%3C/svg%3E%0A");
}
