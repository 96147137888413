.call_to_action {
    @extend .gs_card;
    @extend .gs_link;
    position: relative;
    display: block;
    width: 100%;
    margin: 60px 0;
    padding-left: 200px;
    padding-right: 100px;

    @include max-width($tablet) {
        padding-left: 150px;
        padding-right: 70px;
    }

    @include max-width($phone) {
        border: 1px solid $pale--secondary;
        padding-left: 16px;
        margin: 16px 0;
    }

    &:hover,
    &:focus-visible {
        color: $primary;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateY(-50%);
        top: 50%;
    }

    &::before {
        background-image: url("/static/build/img/call_to_action.png");
        width: 200px;
        height: 176px;
        left: 0;

        @include max-width($tablet) {
            width: 150px;
        }

        @include max-width($phone) {
            display: none;
        }
    }

    &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='94' height='94' viewBox='0 0 94 94' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='94' height='94' rx='47' fill='white'/%3E%3Cpath d='M50 62L68 46.9994L50.0016 32' stroke='%2327ACA1' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M68 47H26' stroke='%2327ACA1' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E");
        width: 60px;
        height: 60px;
        right: 24px;

        @include max-width($tablet) {
            width: 45px;
            height: 45px;
            right: 16px;
        }
    }
}

.call_to_action--secondary {
    &::before {
        background-image: url("/static/build/img/call_to_action--secondary.png");
    }
}
