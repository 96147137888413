.package_card {
	@extend .gs_card;
	background-color: transparent;
	border: 1px solid $pale--secondary;
	display: flex;
	flex-direction: column;
	padding: 0;

	&:nth-of-type(even) {
		background-color: $pale--tertiary;
	}

	&__body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		padding: 20px;

		@include max-width($tablet) {
			padding: 12px 16px;
		}
	}

	&__body > * + * {
		margin-top: 16px;
	}

	img {
		object-fit: cover;
		object-position: center;
		border-radius: $radius $radius 0 0;
		width: 100%;
		height: 120px;
		min-height: 120px;
	}

	.h3 {
		transition: $transition;
	}

	.gs_list--dots {
		font-size: 14px;
		line-height: 1.4;
		color: $pale;
		margin-bottom: auto;
	}

	.old_price {
		font-size: 18px;
		line-height: 1;
		color: $pale--secondary;
		text-decoration: line-through;
	}

	.new_price {
		font-size: 32px;
		font-weight: 700;
		line-height: 1;
		color: $primary;
	}

	.request_price {
		font-size: 18px;
		font-weight: 700;
		line-height: 1;
		color: $primary;
	}
}
