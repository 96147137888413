.supporters {
    display: flex;
    flex-direction: column;

    &__item + &__item {
        margin-top: 24px;

        @include max-width($tablet) {
            margin-top: 12px;
        }
    }

    &__link {
		@extend .gs_link;
        display: block;
		opacity: .85;

		&:hover,
        &:focus-visible {
            opacity: 1;
        }
    }

    &__img {
        width: auto;
        
        @include max-width($tablet) {
			max-height: 70px;
        }
    }
}
