.gs_push_notification {
    position: fixed;
    right: -300px;
    bottom: 0;
    opacity: 0;
    background: $background_gradient;
	user-select: none;
    color: white;
    border-radius: 6px;
    width: auto;
    max-width: 80%;
    margin: 12px;
    padding: 12px;
    z-index: 99;
    animation: slide $transition forwards;
    display: none;

    @include max-width($phone) {
        padding: 8px;
    }

    &--active {
        display: block;
    }
}
