.mfp-bg {
    background-color: $background_overlay;
}

.mfp-container {
    padding: 0 20px;
}

.mfp-fade::after {
    display: none;
}

/* overlay at start */
.mfp-fade.mfp-bg {
    opacity: 0;
    transition: $transition;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
    opacity: 1;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    transition: $transition;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

.mfp-title {
    @include max-width($tablet) {
        display: none;
    }
}

.mfp-close {
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='60' height='60' rx='8' fill='white'/%3E%3Cpath d='M19 19L41.6274 41.6274' stroke='%235D647D' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M41.6274 19L19 41.6274' stroke='%235D647D' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
    font-size: 0 !important;
    padding-right: 0 !important;
    top: 40px !important;
    right: -45px !important;
    opacity: 1 !important;
    cursor: pointer !important;
    width: 40px !important;
    height: 40px !important;

    @include max-width($tablet) {
        top: auto !important;
        bottom: 5px !important;
        right: 50% !important;
        transform: translateX(50%) !important;
        width: 30px !important;
        height: 30px !important;
    }
}
