.gs_list {
	position: relative;
}

.gs_list--dots {

	li + li {
		margin-top: 8px;
	}

	li {
		position: relative;
		padding-left: 18px;
	}

	li::before {
		content: "";
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		left: 0;
		width: 6px;
		height: 6px;
		background-color: $primary;
		border-radius: 50%;
	}
}
