.gs_link {
	outline: none;
	border: none;
	background-color: transparent;
	transition: $transition;
	width: fit-content;

	&:hover,
	&:focus-visible {
		color: $primary--hover;
	}
}

.gs_link--secondary {
	color: $secondary;

	&:hover,
	&:focus-visible {
		color: white;
	}
}

.gs_link--semibold {
	font-weight: 600;
}

.gs_link--underlined {
	text-decoration: underline;
}

.gs_link--colored {
	color: $primary;
}

.gs_link--with_icon {
	display: flex;
	align-items: center;

	svg {
		flex-shrink: 0;
		margin-right: 8px;
	}
}

.gs_link--with_arrow_down {
	@include background("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 6L8.50022 11L13.5 6.00043' stroke='%2327ACA1' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E", $position: right center);
	padding-right: 20px;
}

.gs_link--with_arrow_right {
	@include background("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.03072 12.473L5.71396 11.169L9.65785 7.22514H0.0761719V5.32031H9.65785L5.71396 1.38281L7.03072 0.0724429L13.231 6.27273L7.03072 12.473Z' fill='%2327ACA1'/%3E%3C/svg%3E", $position: right center);
	padding-right: 20px;
}

.gs_link--stretched {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}
