.gs_top_button {
    @include background("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 16L11.9997 8L5 15.9993' stroke='%230C0C3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E", $color: white);
    position: fixed;
    bottom: 20px;
    right: 20px;
	width: 56px;
	height: 56px;
    box-shadow: 0px 28px 53px rgba(0, 25, 80, 0.08), 0px 10.2205px 15.1703px rgba(0, 25, 80, 0.0551985), 0px 4.96184px 8.87643px rgba(0, 25, 80, 0.0445033), 0px 2.43239px 6.24775px rgba(0, 25, 80, 0.0354967), 0px 0.96177px 3.59913px rgba(0, 25, 80, 0.0248015);
	border: 1px solid rgba(207, 215, 220, .25);
	border-radius: 50%;
	transition: $transition;
    z-index: 90;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    
	&:hover,
	&:focus-visible {
		border-color: $pale--secondary;
	}

    &.visible {
        opacity: 1;
        visibility: visible;
    }
}
