.person_card {
	@extend .gs_card;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0 20px 20px;
	margin-top: 80px;

	@include max-width($tablet) {
		margin-top: 68px;
	}

	> *:not(:last-child) {
		margin-bottom: 16px;
	}

	img {
		width: 160px;
		height: 160px;
		object-fit: cover;
		object-position: center;
		border-radius: $radius;
		margin-top: -80px;

		@include max-width($tablet) {
			width: 136px;
			height: 136px;
			margin-top: -68px;
		}
	}

	.bottom_link {
		margin-top: auto;
	}
}
