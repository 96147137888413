.news_detail {
	display: flex;
	flex-direction: column;

	> * + * {
		margin-top: 16px;
	}

	.preview {
		border-radius: $radius;
	}
}
