.user_document {
	@extend .gs_card;
	position: relative;
	display: flex;
	flex-direction: column;
    align-items: center;
	justify-content: center;
	text-align: center;

	> * + * {
		margin-top: 16px;
	}

    .gs_link--stretched {
        border-radius: $radius;
        border: 1px solid transparent;
    }

    .gs_link--stretched:hover,
    .gs_link--stretched:focus-visible {
        border-color: $pale--secondary;
    }

	.gs_link--stretched + * {
		margin-top: 0;
	}

	.file_link {
		border: 1px solid transparent;
		border-radius: 50%;
	}

	.file_link:hover,
	.file_link:focus-visible {
		border-color: $pale--secondary;
	}

	.file_name {
		margin-bottom: 16px;
	}

    .buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
		margin-top: auto;
    }

    .buttons > * + * {
        margin-top: 8px;
    }

	svg {
		@include max-width($tablet) {
			width: 96px;
			height: 96px;
		}
	}
}
