.faq_card {
    @extend .gs_card;
    padding: 0;

    & + & {
        margin-top: 16px;
    }
    
    &.active &__title::before {
        transform: translateY(-50%) rotate(-180deg);
    }
    
    &__title {
        @extend .h3;
        @extend .gs_link;
        width: 100%;
        position: relative;
        text-align: left;
        padding: 24px;
        padding-right: calc(24px + 24px);
        transition: $transition;
        cursor: pointer;
    
        @include max-width($tablet) {
            padding: 16px;
            padding-right: calc(16px + 24px);
        }
    }
    
    &__title::before {
        content: "";
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 24px;
        width: 24px;
        height: 24px;
        background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38 16L23.9994 32L10 16.0014' stroke='%230C0C3D' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat center / contain;
        transition: $transition;
    
        @include max-width($tablet) {
            right: 16px;
        }
    }
    
    &__body {
        padding: 0 24px 24px;
        display: none;
    
        @include max-width($tablet) {
            padding: 0 16px 16px;
        }
    } 
}
