.promo_benefit_card {
	@extend .gs_card;
    border-radius: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding-top: 0;
    margin-top: 30px;

    @include max-width($tablet) {
        margin-top: 25px;
    }

    &__icon {
        display: inline-flex;
		justify-content: center;
		align-items: center;
        background-color: $primary;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        padding: 12px;
        margin-top: -30px;
        margin-bottom: 16px;

        @include max-width($tablet) {
			width: 50px;
			height: 50px;
            margin-top: -25px;
		}
    }

    &__title {
        @extend .h3;
    }
}
