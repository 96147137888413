/* fade image in after load */
.lazyload,
.lazyloading {
	opacity: 0;
	filter: blur(10px);
}

.lazyloaded {
	opacity: 1;
	filter: none;
	transition: filter .25s, opacity .5s;
}
