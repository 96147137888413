*,
*::before,
*::after {
    box-sizing: border-box;
}

*::before,
*::after {
    pointer-events: none;
}

ul,
ol {
    list-style: none;
    padding: 0;
}

fieldset {
	margin: 0;
	padding: 0;
	border: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

body {
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

hr {
    width: 100%;
    border: none;
    border-top: 1px solid $secondary;
}

img {
    display: block;
    user-select: none;
    -webkit-user-drag: none;
    max-width: 100%;
}

iframe {
	display: block;
	width: 100%;
}

address {
	font-style: normal;
}

input,
button,
textarea,
select {
    font: inherit;
}

textarea {
	display: block;
	resize: none;
}

button {
    cursor: pointer;
    padding: 0;
	border: none;
}

a {
    text-decoration-skip-ink: auto;
    text-decoration: none;
    color: inherit;
}

svg {
    vertical-align: middle;
}

table {
    width: 100%;
    table-layout: fixed;
   	border-collapse: collapse;
	border-spacing: 0;
}

th {
    text-align: left;
    font-weight: 500;
}

td {
    vertical-align: top;
}

/* Удаляем все анимации и переходы для людей, которые предпочитают их не использовать */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
