// Prevents animations on page load
html {
	opacity: 1 !important;
	display: initial !important;
}

#specialButton {
	cursor: pointer;
	width: 32px;
	height: auto;

	@include max-width($phone) {
		margin: 0 10px;
	}
}

#specialButton ~ .gs_button {
	@include max-width($phone) {
		padding: 9px;
	}
}

.special body {
	margin-top: 0 !important;
	margin-bottom: 100px !important;
}

.special .shadow {
	background-color: $background_overlay !important;
}

.special .jquery-modal {
	background: $background_overlay !important;
}

.special .gs_modal {
	background: none !important;
}

.special .gs_modal .gs_modal__top,
.special .gs_modal .gs_modal__top * {
	background: none !important;
}

.special .intro_overlay::before {
	display: none !important;
}

.special .intro_content,
.special .intro_content * {
	background: none !important;
}

.special .picture--1,
.special .picture--2,
.special .picture--3 {
	display: none !important;
}

.special .gs_link--stretched {
	background: none !important;
}

.special .gs_top_button {
	@include background("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 16L11.9997 8L5 15.9993' stroke='%230C0C3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E", $color: white);
	bottom: 100px;
}

.special .hamburger * {
	background: black !important;
}
.special.special-color-2 .hamburger * {
	background: white !important;
}

.special .gs_button,
.special .index--grand_class .grand_class_card,
.special .partnership--feedback .feedback_card,
.special .event--documents .consultation_card,
.special .partnership_card .gs_card,
.special .partnership_type,
.special .invitation_card,
.special .active_event_card,
.special .promotion_card,
.special .benefit_card,
.special .payment_card,
.special .application {
	background: none !important;
	border: 1px solid black !important;
}

.special .application .payment_card {
	border: none !important;
}

.special .event--documents .consultation_card::before {
	display: none !important;
}

.special.special-color-2 .active_event_card,
.special.special-color-2 .gs_button {
	border: 1px solid white !important;
}

.special .benefit_card__icon {
	background: none !important;
}
.special .benefit_card__icon * {
	fill: black !important;
}
.special .benefit_card__icon img {
	background: none !important;
}
.special.special-color-2 .benefit_card__icon * {
	fill: white !important;
}

.special .promo_benefit_card__icon * {
	fill: black !important;
}
.special.special-color-2 .promo_benefit_card__icon * {
	fill: white !important;
}

.special .swiper-button-prev,
.special .swiper-button-next {
	@include background("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 19L17 11.9997L9.00069 5' stroke='%230C0C3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E", $color: white);
}

.special .payment_card__counter {
	background-repeat: no-repeat !important;
	background-position: center !important;
	background-size: 50% !important;
}
.special .payment_card__counter--less {
	background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.31372 24.3135H40.3137' stroke='black' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") !important;
}
.special .payment_card__counter--more {
	background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.31372 24.3135H40.3137' stroke='black' stroke-width='3' stroke-linecap='round'/%3E%3Cpath d='M24.3137 8.31348V40.3135' stroke='black' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") !important;
}

.special picture:not(img[src]) {
	visibility: hidden !important;
}
.special picture:has(img[src]) {
	visibility: visible !important;
}

.special select {
	background-color: transparent !important;
	background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6L8.00022 11L13 6.00043' stroke='%230C0C3D' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;	background-repeat: no-repeat !important;
	background-size: 24px !important;
	background-position-y: center !important;
	background-position-x: calc(100% - 16px) !important;
}
