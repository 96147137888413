.text--primary {
	color: $primary;
}

.text--secondary {
	color: $secondary;
}

.text--pale {
	color: $pale;
}

.text--small_size {
	font-size: 12px;
	line-height: 16px;
}

.text--medium_size {
	font-size: 14px;
	line-height: 20px;
}

.text--left {
	text-align: left;
}

.text--center {
	text-align: center;
}

.text--right {
	text-align: right;
}

.text--normal {
	font-weight: 400;
}

.text--medium {
	font-weight: 500;
}

.text--semibold {
	font-weight: 600;
}

.text--bold {
	font-weight: 700;
}

.text--underlined {
	text-decoration: underline;
}
