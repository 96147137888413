.gs_button {
	position: relative;
	overflow: hidden;
    display: inline-flex;
	align-items: center;
	justify-content: center;
    text-align: center;
    border: none;
    outline: none;
    border-radius: $radius--medium;
    padding: 16px 32px;
    transition: $transition;
    font-weight: 600;
	width: fit-content;
    min-height: 46px;

    @include max-width($tablet) {
        padding: 12px 24px;
    }
}

.gs_button--primary {
    color: white;
    background-color: $primary;

    &:hover,
    &:focus-visible {
        background-color: $primary--hover;
    }

    &:active {
        background-color: $primary--dark;
    }
}

.gs_button--secondary {
    color: $primary;
    background-color: $secondary;

    &:hover,
    &:focus-visible {
        color: $primary--dark;
    }

    &:active {
        background-color: $pale--tertiary;
    }
}

.gs_button--white {
    color: $primary;
    background-color: white;

    &:hover,
    &:focus-visible {
        color: $primary--dark;
    }

    &:active {
        color: $olive;
    }
}

.gs_button--disabled {
    user-select: none;
	pointer-events: none;
	color: $pale !important;
}

.gs_button--small {
    padding: 9px 24px;
}

.gs_button--large {
	@include fluid-text($phone, $desktop_large, 14px, 36px, 22px, 46px);
    padding: 24px 40px;
	font-weight: 500;

	@include max-width($tablet) {
        padding: 12px 24px;
    }
}

.gs_button--full {
	width: 100%;
}

.gs_button--margin {
	margin-top: 32px;

	@include max-width($tablet) {
        margin-top: 16px;
    }
}

.gs_button--with_icon {
    display: flex;
    align-items: center;
    padding-left: 16px;

    svg {
        flex-shrink: 0;
        margin-right: 12px;
    }
}
