.datepicker--day-name,
.datepicker--cell.datepicker--cell-day.-current-,
.datepicker--button {
	color: $primary;
}

.datepicker--cell.datepicker--cell-day.-selected-,
.datepicker--cell.datepicker--cell-day.-focus-,
.datepicker--button:hover {
    background-color: $secondary;
    color: $olive;
}
