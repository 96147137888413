.gs_education_step {
    @extend .gs_card;
    display: flex;
    flex-direction: column;
    width: calc(100% - 136px);

    @include max-width($tablet_wide) {
        width: calc(100% - 68px);
    }

    @include max-width($phone) {
        width: 100%;
    }

    > * + * {
        margin-top: 16px;
    }

    &:nth-of-type(even) {
        margin-left: auto;
    }

    &__header {
        display: flex;
        align-items: center;
    
        @include max-width($phone) {
            align-items: flex-start;
            flex-direction: column;
        }
    }

    &__header > * + * {
        margin-left: 24px;
    
        @include max-width($phone) {
            margin-left: 0;
            margin-top: 16px;
        }
    }

    &__number {
        @include fluid-text($phone, $desktop_large, 18px, 28px, 22px, 40px);
        font-weight: 500;
        color: white;
        background-color: $primary;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 60px;
        height: 60px;
    
        @include max-width($tablet_wide) {
            width: 60px;
            height: 60px;
        }
    
        @include max-width($tablet) {
            width: 48px;
            height: 48px;
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
    }

    &__description > * + * {
        margin-top: 8px;
    }

    &__images {
        display: flex;
    }

    &__images > * {
        width: 100%;
    }

    &__images > * + * {
        margin-left: 8px;
    }
    
    img {
        margin: 0 auto;
        max-height: 400px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top left;
        border-radius: $radius--medium;
    }
}
